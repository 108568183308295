export function getFolderIconsByColor(fromColor, toColor) {

    const checkBoxSvg = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="url(#paint0_linear_1072_4505)" />
        <circle cx="9" cy="9" r="5" fill="url(#paint1_linear_1072_4505)" />
        <defs>
            <linearGradient id="paint0_linear_1072_4505" x1="-0.947368" y1="-5.0339" x2="24.1807" y2="-1.73422" gradientUnits="userSpaceOnUse">
                <stop stopColor={`#${fromColor}`} />
                <stop offset="1" stopColor={`#${toColor}`} />
            </linearGradient>
            <linearGradient id="paint1_linear_1072_4505" x1="3.47368" y1="1.20339" x2="17.4337" y2="3.03654" gradientUnits="userSpaceOnUse">
                <stop stopColor={`#${fromColor}`} />
                <stop offset="1" stopColor={`#${toColor}`} />
            </linearGradient>
        </defs>
    </svg>

    return checkBoxSvg;
}