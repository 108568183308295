import moment from 'moment';


export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength); // Truncate the string to maxLength characters
  } else {
    return str; // Return the original string if its length is within the limit
  }
}

export const repharseYahooMessage = (message, fromstart, toend) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(message, "text/html");
  // const whenElement = Array.from(doc.querySelectorAll("td")).find(
  //   (td) => td.textContent.trim() === "When"
  // );
  // if (whenElement) {
  //   const dateContent = whenElement?.nextElementSibling?.nextElementSibling;
  //   if (dateContent) {
  //     dateContent.innerHTML = `${fromstart} - ${toend}`;
  //   }
  // }
  const tentativeSpan = doc.querySelector('span[style*="color: #f58d31;"]');
  if (tentativeSpan) {
    tentativeSpan.style.fontWeight = "normal";
  }
  const modifiedHTML = doc.body.innerHTML;
  return modifiedHTML;
}

export const convertToTimeZone = (fromDate, toDate, fromTime, toTime, timeZone) => {
  const fromdate = formatToMMDDYYYY(fromDate)
  const toconverteddate = formatToMMDDYYYY(toDate)
  const fromDateTime = moment.tz(`${fromdate} ${fromTime}`, "MM/DD/YYYY hh:mm A", timeZone || moment.tz.guess());
  const toDateTime = moment.tz(`${toconverteddate} ${toTime}`, "MM/DD/YYYY hh:mm A", timeZone || moment.tz.guess());
  const formattedFrom = formatDateToCustomString(fromDateTime.toDate());
  const formattedTo = formatDateToCustomString(toDateTime.toDate());
  return { formattedFrom, formattedTo };
};

const formatDateToCustomString = (dateObj) => {
  if (!(dateObj instanceof Date)) {
    throw new TypeError("Expected a Date object");
  }
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  const seconds = "00";
  const formattedString = `${year}${month}${day}T${hours}${minutes}${seconds}`;
  return formattedString;
};

function formatToMMDDYYYY(input) {
  const dateObj = new Date(input);
  if (!isNaN(dateObj.getTime())) {
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const converteddate = `${month}/${day}/${year}`
    return converteddate;
  }
  return null;
}