// Importing the Dexie library
import Dexie from 'dexie';

// Creating a new Dexie database instance named 'web3Dmails'
const db = new Dexie('web3Dmails');

// Defining the structure of the database with a single table named 'emails'
db.version(1).stores({
    inbox: '++id, mailId, decryptedMail , type' ,
    sent: '++id, mailId, decryptedMail , type' ,
    trash: '++id, mailId, decryptedMail , type',
    insecure: '++id, mailId, decryptedMail , type',
    outbox: '++id, json',
    attachment: '++id, name, s3url, file',
    folders: '++id, mailId, decryptedMail , type',
    important: '++id, mailId, decryptedMail , type',
    senttrash: '++id, mailId, decryptedMail , type',
    archive: '++id, mailId, decryptedMail , type',
    draft: '++id, json',
});

// Exporting the Dexie database instance to be used in other modules
export default db;
