export const getBrowserDetails = async () => {
    let ip = null;
    await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => (ip = data.ip))
        .catch(error => console.error('Error fetching IP address:', error));
    const details = {
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        ip: ip
    };
    return JSON.stringify(details);
};