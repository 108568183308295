export const timezoneMapping = {
    "UTC": "Etc/UTC",
    "GMT": "Etc/GMT",
    "IST": "Asia/Kolkata",
    "India Standard Time": "Asia/Kolkata",
    "Pacific Standard Time": "America/Los_Angeles",
    "Mountain Standard Time": "America/Denver",
    "Central Standard Time": "America/Chicago",
    "Eastern Standard Time": "America/New_York",
    "Atlantic Standard Time": "America/Halifax",
    "Greenwich Mean Time": "Etc/GMT",
    "British Summer Time": "Europe/London",
    "Central European Time": "Europe/Berlin",
    "Eastern European Time": "Europe/Helsinki",
    "Australian Eastern Standard Time": "Australia/Sydney",
    "Australian Central Standard Time": "Australia/Adelaide",
    "New Zealand Standard Time": "Pacific/Auckland",
    "Singapore Standard Time": "Asia/Singapore",
    "China Standard Time": "Asia/Shanghai",
    "Japan Standard Time": "Asia/Tokyo",
    "Korea Standard Time": "Asia/Seoul",
    "Israel Standard Time": "Asia/Jerusalem",
    "Moscow Standard Time": "Europe/Moscow",
    "Samoa Standard Time": "Pacific/Apia",
    "Hawaii-Aleutian Standard Time": "Pacific/Honolulu",
    "Alaska Standard Time": "America/Anchorage",
    "Arabian Standard Time": "Asia/Riyadh",
    "South Africa Standard Time": "Africa/Johannesburg",
    "Argentina Standard Time": "America/Argentina/Buenos_Aires",
    "Chile Standard Time": "America/Santiago",
    "Iran Standard Time": "Asia/Tehran",
    "Indochina Time": "Asia/Bangkok",
    "Malaysia Standard Time": "Asia/Kuala_Lumpur",
    "Nepal Time": "Asia/Kathmandu",
    "Philippine Standard Time": "Asia/Manila",
    "Turkey Time": "Europe/Istanbul",
    "Central Daylight Time": "America/Chicago",
    "Mountain Daylight Time": "America/Denver",
    "Eastern Daylight Time": "America/New_York",
    "Alaska Daylight Time": "America/Anchorage",
    "Pacific Daylight Time": "America/Los_Angeles",
    "Argentina Time": "America/Argentina/Buenos_Aires",
    "Fiji Standard Time": "Pacific/Fiji",
    "Chatham Standard Time": "Pacific/Chatham",
    "Norfolk Island Time": "Pacific/Norfolk",
    "Cuba Standard Time": "America/Havana",
    "Hawaii Standard Time": "Pacific/Honolulu",
    "Tonga Standard Time": "Pacific/Tongatapu",
    "Kamchatka Standard Time": "Asia/Kamchatka",
    "Anadyr Standard Time": "Asia/Anadyr",
    "Vladivostok Standard Time": "Asia/Vladivostok",
    "Magadan Standard Time": "Asia/Magadan",
    "Marquesas Time": "Pacific/Marquesas",
    "Niue Time": "Pacific/Niue",
    "Azores Standard Time": "Atlantic/Azores",
    "Bermuda Standard Time": "Atlantic/Bermuda",
    "Cape Verde Time": "Atlantic/Cape_Verde",
    "Greenland Time": "America/Godthab",
    "Seychelles Time": "Indian/Mahe",
    "Mauritius Time": "Indian/Mauritius",
    "Afghanistan Time": "Asia/Kabul",
    "Azerbaijan Standard Time": "Asia/Baku",
    "Georgia Standard Time": "Asia/Tbilisi",
    "Armenia Standard Time": "Asia/Yerevan",
    "Samara Time": "Europe/Samara",
    "Israel Daylight Time": "Asia/Jerusalem",
    "Pacific Time": "America/Los_Angeles",
    "Mountain Time": "America/Denver",
    "Central Time": "America/Chicago",
    "Eastern Time": "America/New_York",
    "W. Australia Standard Time": "Australia/Perth",
    "W. Australia Daylight Time": "Australia/Perth",
    "Korea Daylight Time": "Asia/Seoul",
    "Iran Daylight Time": "Asia/Tehran",
    "India Daylight Time": "Asia/Kolkata",
    "Central African Time": "Africa/Bangui",
    "Ecuador Time": "America/Guayaquil",
    "Western European Time": "Europe/Lisbon",
    "Eastern European Summer Time": "Europe/Helsinki",
    "Iran Standard Time": "Asia/Tehran",
    "Danish Time": "Europe/Copenhagen",
    "Egypt Standard Time": "Africa/Cairo",
    "Indonesia Western Time": "Asia/Jakarta",
    "Indonesia Central Time": "Asia/Makassar",
    "Indonesia Eastern Time": "Asia/Jayapura",
    "Hong Kong Time": "Asia/Hong_Kong",
    "Taiwan Time": "Asia/Taipei",
    "Macau Time": "Asia/Macau",
    "Tajikistan Time": "Asia/Dushanbe",
    "Uzbekistan Time": "Asia/Tashkent",
    "Yekaterinburg Time": "Asia/Yekaterinburg",
    "Kazakhstan Time": "Asia/Almaty",
    "Sri Lanka Time": "Asia/Colombo",
    "Maldives Time": "Indian/Maldives",
    "West Africa Time": "Africa/Lagos",
    "East Africa Time": "Africa/Nairobi",
    "Central African Time": "Africa/Bangui",
    "South Sudan Time": "Africa/Juba",
    "Sudan Time": "Africa/Khartoum",
    "Uganda Time": "Africa/Kampala",
    "Zanzibar Time": "Africa/Dar_es_Salaam",
    "Rwanda Time": "Africa/Kigali",
    "Burundi Time": "Africa/Gitega",
    "Malawi Time": "Africa/Blantyre",
    "Botswana Time": "Africa/Gaborone",
    "Lesotho Time": "Africa/Maseru",
    "Eswatini Time": "Africa/Mbabane",
    "Namibia Time": "Africa/Windhoek",
    "Angola Time": "Africa/Luanda",
    "Ghana Time": "Africa/Accra",
    "Togo Time": "Africa/Lome",
    "Senegal Time": "Africa/Dakar",
    "Mali Time": "Africa/Bamako",
    "Burkina Faso Time": "Africa/Ouagadougou",
    "Ivory Coast Time": "Africa/Abidjan",
    "Liberia Time": "Africa/Monrovia",
    "Sierra Leone Time": "Africa/Freetown",
    "Nigeria Time": "Africa/Lagos",
    "Cameroon Time": "Africa/Douala",
    "Republic of the Congo Time": "Africa/Brazzaville",
    "Democratic Republic of the Congo Time": "Africa/Kinshasa",
    "Gabon Time": "Africa/Libreville",
    "Equatorial Guinea Time": "Africa/Malabo",
    "Central African Time": "Africa/Bangui",
    "Chad Time": "Africa/N'Djamena",
    "Niger Time": "Africa/Niamey",
    "Tanzania Time": "Africa/Dar_es_Salaam",
    "Togo Time": "Africa/Lome",
    "Congo Time": "Africa/Brazzaville",
    "Fiji Time": "Pacific/Fiji",
    "Chamorro Time": "Pacific/Guam",
    "Samoa Time": "Pacific/Apia",
    "Cook Islands Time": "Pacific/Rarotonga",
    "Tahiti Time": "Pacific/Tahiti",
    "Galapagos Time": "Pacific/Galapagos",
    "Aleutian Time": "America/Adak",
    "Central Daylight Time": "America/Chicago",
    "Eastern Daylight Time": "America/New_York",
    "Mountain Daylight Time": "America/Denver",
    "Pacific Daylight Time": "America/Los_Angeles",
    "Atlantic Daylight Time": "America/Halifax",
};
