import Web3 from 'web3'; // Importing Web3 library
import web3Config from '../web3/web3Config.json'; // Importing contract JSON file


// Extracting contract address from config file
// Initializing Web3 with the injected Ethereum provider (e.g., MetaMask)
const web3 = new Web3(window.ethereum);
export async function connectWallet(isSignin=false){

    const chainId = parseInt(web3Config.CHAIN_ID);    

    try {
    // Requesting MetaMask to connect
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    
    if (window.ethereum.networkVersion !== chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(chainId) }]
          });
        } catch (err) {
          if (err.code === 4902) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: web3Config.CHAIN_DETAILS.chainName,
                  chainId: web3.utils.toHex(chainId),
                  nativeCurrency: { name: web3Config.CHAIN_DETAILS.nativeCurrency.name , decimals: web3Config.CHAIN_DETAILS.nativeCurrency.decimals, symbol: web3Config.CHAIN_DETAILS.nativeCurrency.symbol },
                  rpcUrls: web3Config.CHAIN_DETAILS.rpcUrls,
                  blockExplorerUrls: web3Config.CHAIN_DETAILS.blockExplorerUrls,
                },
              ]
            });
          }else{
            return null;
          }
        }
      }


    if (accounts.length > 0) {

        if(isSignin) {
            const message = "hi there (from Web3)! \n\nTo ensure your ownership of this address, we need you to sign this message. \n\nThis request WILL NOT trigger a blockchain transaction or cost you any gas fees.";
            await window.ethereum.request({
                method: 'personal_sign',
                params: [message, accounts[0]]
            });

        };
        return accounts[0];

    } else {
        return null;
    }
} catch (error) {
    console.log(error)
    return null;
}
}
