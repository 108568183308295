import { getCalendarContract } from "./contract-helper";
import Cookies from "universal-cookie";
import moment from "moment";
import { timezoneMapping } from "../components/Timezone/Timezone";
import config from "../web3/web3Config.json";

const cookies = new Cookies();
const userData = cookies.get("userObject");

export const getEventsByUser = async (users) => {
    if (!Array.isArray(users) || users.length === 0) {
        console.error("[ERROR]: Invalid user array.");
        return [];
    }

    try {
        const promises = users.map(async (user) => {
            // Check if the user's domain matches the allowed domain
            if (user.split('@')[1] === config.DOMAIN) {
                // Return the promise from the contract call
                const contractInstance = getCalendarContract();
                const userSettings = await contractInstance.methods.getSettings(user).call({ from: userData.wallet })
                const findPublicSettings = findSettingByKey(userSettings, 'isPublic');
                if (findPublicSettings.length > 0 && findPublicSettings[0] === 'true') {
                    return await contractInstance.methods
                        .getAllEvents(user)
                        .call({ from: userData.wallet });
                } else {
                    return [];
                }
            } else {
                // Return a resolved promise with an empty array to maintain structure
                return Promise.resolve([]);
            }
        });

        const results = await Promise.all(promises);

        // Flatten the array of results into a single array
        const allEvents = results.flat();

        return allEvents;
    } catch (error) {
        console.error("[ERROR_FETCHING_EVENTS]: ", error);
        return [];
    }
};

export const adjustToUTCAndAddOffset = (timeVal) => {
    let selectedTimezone = localStorage.getItem('timeZone') ?? null;
    const year = timeVal.substring(0, 4);
    const month = parseInt(timeVal.substring(4, 6)) - 1;
    const day = timeVal.substring(6, 8);
    const hours = timeVal.substring(9, 11);
    const minutes = timeVal.substring(11, 13);
    const seconds = timeVal.substring(13, 15);
    if (!selectedTimezone) {
        const localDate = new Date(year, month, day, hours, minutes, seconds);
        const offsetInMilliseconds = new Date().getTimezoneOffset() * 60000;
        return new Date(localDate.getTime() - offsetInMilliseconds);
    }
    else {
        const { formattedTime, totalDate, nyTime } = timezoneconversation(timeVal);
        return new Date(nyTime);
    }
};

export const mergeOverlappingEvents = (events) => {
    if (!events || events.length === 0) return [];

    // Filter events to ensure start and end dates are the same
    const filteredEvents = events.filter(event => {
        const startDate = event.start.toLocaleDateString();
        const endDate = event.end.toLocaleDateString();
        return startDate === endDate;
    });

    if (filteredEvents.length === 0) return []; // No valid events to process

    // Sort events by start time
    const sortedEvents = filteredEvents.sort(
        (a, b) => a.start.getTime() - b.start.getTime()
    );

    const merged = [];
    let currentEvent = { ...sortedEvents[0] }; // Clone the first event

    for (let i = 1; i < sortedEvents.length; i++) {
        const nextEvent = sortedEvents[i];
        if (currentEvent.end >= nextEvent.start) {
            // Events overlap; merge them
            currentEvent.end = new Date(
                Math.max(currentEvent.end.getTime(), nextEvent.end.getTime())
            );
        } else {
            // No overlap; push current event to merged list
            merged.push(currentEvent);
            currentEvent = { ...nextEvent }; // Move to the next event
        }
    }

    // Push the last event after the loop
    merged.push(currentEvent);

    return merged;
};


export function findSettingByKey(settings, searchKey) {
    const result = [];

    settings.forEach((setting) => {
        const items = setting.contact || [];
        items.forEach((item) => {
            if (item.key === searchKey) {
                result.push(item.value);
            }
        });
    });

    return result;
}

export const formatEventsDetails = (eventDetails) => {
    return eventDetails?.flatMap((event) => {
        const repeatEventKey = event?.list?.find(
            (key) => key.key === "repeatEvent"
        );

        const customRepeatEventKey = event?.list?.find(
            (key) => key.key === "customRepeatEvent"
        );

        const singleEvents = event?.list
            ?.filter((key) => key.key === "singleEvent")
            .map((key) => key.value);

        if (!repeatEventKey) {
            return [{ ...event }]; // If no repeatEvent key exists, return the original event
        }

        // repeat Event Keys.
        const repeatType = repeatEventKey.value?.split("_")?.[0] || [];
        const repeatTypeValue = repeatEventKey.value?.split("_")?.[1] || "";
        const originalStartDate = moment(event.start);
        const originalEndDate = moment(event.end);
        const events = [];
        let iterations = 0;

        // Custom Event Keys.
        let repeatEventObject = parseEvent([repeatEventKey?.value]);
        let customRepeatObject = parseEvent([customRepeatEventKey?.value]);

        const formattedSingleEvents = singleEvents
            ?.filter(Boolean)
            .map((singleEvent) => {
                const date = moment(singleEvent, "YYYYMMDD");
                return date.isValid() ? date.format("YYYY-MM-DD") : null;
            })
            .filter(Boolean);

        switch (repeatType) {
            case "daily":
                events.push({ ...event });
                iterations = 730; // 2 years
                if (repeatEventObject?.endDate) {
                    iterations = intervalCount(
                        repeatEventObject?.endDate,
                        originalStartDate,
                        "days"
                    );
                }

                for (let index = 0; index < iterations; index++) {
                    const newStartDate = originalStartDate.clone().add(index + 1, "days");
                    const newEndDate = originalEndDate.clone().add(index + 1, "days");
                    if (formattedDate(newStartDate, formattedSingleEvents)) {
                        continue;
                    }
                    if (repeatTypeValue?.length && !repeatEventObject?.endDate) {
                        const daysArray = repeatTypeValue
                            .split(",")
                            .map((day) => getDayName(day));
                        if (daysArray.includes(newStartDate.day())) {
                            events.push({
                                ...event,
                                start: newStartDate.toDate(),
                                end: newEndDate.toDate(),
                            });
                        }
                    } else {
                        events.push({
                            ...event,
                            start: newStartDate.toDate(),
                            end: newEndDate.toDate(),
                        });
                    }
                }
                break;

            case "weekly":
                iterations = 104; // 2 year
                let weekDays = repeatTypeValue;
                if (/,/.test(weekDays)) {
                    // Handle multiple days (e.g., MO,TU)
                    weekDays = weekDays;
                } else {
                    // Handle single day (e.g., SU)
                    weekDays = weekDays.slice(0, 2).toUpperCase()
                };
                if (shuffleAndCheckMatch(weekDays)) {
                    let currentDate = originalStartDate.clone();
                    let twoYearsLater = originalStartDate.clone().add(2, "years");

                    if (repeatEventObject?.endDate) {
                        twoYearsLater = moment(repeatEventObject?.endDate);
                    }
                    while (currentDate.isBefore(twoYearsLater)) {
                        if (formattedDate(currentDate, formattedSingleEvents)) {
                            continue;
                        }
                        // Skip weekends (Saturday and Sunday)
                        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
                            // Calculate the new end date based on the original duration
                            const newEndDate = currentDate
                                .clone()
                                .add(
                                    originalEndDate.diff(originalStartDate, "minutes"),
                                    "minutes"
                                );
                            events.push({
                                ...event,
                                start: currentDate.toDate(),
                                end: newEndDate.toDate(),
                            });
                        }
                        // Move to the next day
                        currentDate.add(1, "day");
                    }
                } else {
                    events.push({ ...event });
                    // Calculate iterations based on endDate
                    if (repeatEventObject?.endDate) {
                        iterations = intervalCount(
                            repeatEventObject?.endDate,
                            originalStartDate,
                            "weeks"
                        );
                    }
                    for (let index = 0; index < iterations; index++) {
                        const newStartDate = originalStartDate
                            .clone()
                            .add(index + 1, "weeks");
                        const newEndDate = originalEndDate.clone().add(index + 1, "weeks");
                        if (formattedDate(newStartDate, formattedSingleEvents)) {
                            continue;
                        }
                        events.push({
                            ...event,
                            start: newStartDate.toDate(),
                            end: newEndDate.toDate(),
                        });
                    }
                }
                break;

            case "monthly":
                iterations = 24; // 2 years

                // Calculate iterations based on endDate
                if (repeatEventObject?.endDate) {
                    iterations = intervalCount(
                        repeatEventObject?.endDate,
                        originalStartDate,
                        "months"
                    );
                }

                for (let index = 0; index < iterations; index++) {
                    const baseDate = originalStartDate.clone().add(index, "months").startOf("month");
                    const [week, day] = repeatTypeValue.match(/(-?\d+)(\w+)/).slice(1);
                    let weekIndex = parseInt(week, 10), targetDay = baseDate.clone().day(day);

                    if (weekIndex < 0) {
                        targetDay = baseDate.clone().endOf("month").day(day).add((weekIndex + 1) * 7, "days");
                        targetDay.startOf('day');
                        if (targetDay.month() > baseDate.month()) targetDay.subtract(7, "days");
                    } else {
                        if (targetDay.month() < baseDate.month()) targetDay.add(7, "days");
                        targetDay.add((weekIndex - 1) * 7, "days");
                    }

                    if (targetDay.month() === baseDate.month() && !formattedDate(targetDay, formattedSingleEvents)) {
                        events.push({
                            ...event,
                            start: targetDay.toDate(),
                            end: targetDay.clone().add(originalEndDate.diff(originalStartDate)).toDate(),
                        });
                    }
                }
                break;

            case "yearly":
                events.push({ ...event });
                iterations = 10; // 10 years

                // Calculate iterations based on endDate
                if (repeatEventObject?.endDate) {
                    iterations = intervalCount(
                        repeatEventObject?.endDate,
                        originalStartDate,
                        "year"
                    );
                }

                for (let index = 0; index < iterations; index++) {
                    const newStartDate = originalStartDate.clone().add(index + 1, "year");
                    const newEndDate = originalEndDate.clone().add(index + 1, "year");
                    if (formattedDate(newStartDate, formattedSingleEvents)) {
                        continue;
                    }
                    events.push({
                        ...event,
                        start: newStartDate.toDate(),
                        end: newEndDate.toDate(),
                    });
                }
                break;

            case "custom":
                return generateRecurringEvents(
                    customRepeatObject,
                    event,
                    originalStartDate,
                    originalEndDate,
                    formattedSingleEvents
                );
            default:
                return [{ ...event }]; // If the repeat type is not handled, return the original event
        }
        return events;
    });
};

function timezoneconversation(timeVal) {
    // Parse the input time
    const utcTime = moment.utc(timeVal, 'YYYYMMDDTHHmmss');

    // Check if hours, minutes, and seconds are all 0
    const hasTimeComponent = utcTime.hours() !== 0 ||
        utcTime.minutes() !== 0 ||
        utcTime.seconds() !== 0;

    if (!hasTimeComponent) {
        // If no time component, return date only formats
        return {
            formattedTime: '12:00 AM',  // Empty string for time since it's a date-only value
            totalDate: utcTime.format('MM/DD/YYYY'),
            nyTime: utcTime.format('YYYY-MM-DD 00:00:00')
        };
    } else {
        // If there is a time component, perform timezone conversion
        let selectedTimezone = localStorage.getItem('timeZone') ?? null;
        const timeZoneToUse = timezoneMapping[selectedTimezone] || selectedTimezone || "Etc/UTC";
        const localTime = utcTime.tz(timeZoneToUse);
        return {
            formattedTime: localTime.format('h:mm A'),
            totalDate: localTime.format('MM/DD/YYYY'),
            nyTime: localTime.format('YYYY-MM-DD HH:mm:ss')
        };
    }
}

function parseEvent(event) {
    const parsedValues = [];
    const parsedObject = {};
    if (event?.length) {
        const eventType = event[0]?.split("_") || [];
        eventType.forEach((item) => {
            if (item) {
                const [key, value] = item.split("~");
                if (key && value) {
                    parsedValues.push({ [key]: value });
                    parsedObject[key] = value;
                }
            }
        });
    }
    return parsedObject;
}

function intervalCount(endDate, originalStartDate, type) {
    const endMoment = moment(endDate);
    return Math.floor((endMoment.diff(originalStartDate, type) + 1) / 1) - 1;
}

function formattedDate(newStartDate, formattedSingleEvents) {
    return (
        formattedSingleEvents?.includes(
            moment(newStartDate).format("YYYY-MM-DD")
        ) ?? false
    );
}

function getDayName(dayNum) {
    const daysOfWeek = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    return daysOfWeek.indexOf(dayNum);
}

export const shuffleAndCheckMatch = (daysString) => {
    if (!daysString) return false;
    const original = "MO,TU,WE,TH,FR";
    const daysArray = daysString.split(",");
    return (
        original?.split(",")?.sort()?.join(",") === daysArray?.sort()?.join(",")
    );
};

const generateRecurringEvents = (custom, event, originalStartDate, originalEndDate, formattedSingleEvents) => {
    try {
        const events = [];
        const { unit, interval, endDate, count, byday, customMonth, bymonth } =
            custom || {};
        const defaultIterations = {
            daily: 730,
            weekly: 104,
            monthly: 24,
            yearly: 10,
        };
        const intervals = parseInt(interval) || 1;
        let iterations = defaultIterations[unit] || 0;

        // Push the first event
        events.push({ ...event });

        // Calculate iterations based on endDate
        if (endDate) {
            const endMoment = moment(endDate);
            iterations = Math.floor(
                endMoment.diff(
                    originalStartDate,
                    unit === "daily"
                        ? "days"
                        : unit === "weekly"
                            ? "weeks"
                            : unit === "monthly"
                                ? "months"
                                : "years"
                ) +
                1 / intervals
            );
        }

        // Override iterations with count if provided
        if (count) {
            iterations = parseInt(count);
        }

        const calculateNewEvent = (newStartDate, newEndDate) => ({
            ...event,
            start: newStartDate.toDate(),
            end: newEndDate.toDate(),
        });
        if (byday) {
            const daysArray = byday?.split(",").map((day) => getDayName(day));
            iterations = iterations(daysArray?.length * daysArray?.length)
        }
        // Generate events based on the unit
        for (let index = 1; index < iterations; index++) {
            if (unit === "daily") {
                const newStartDate = originalStartDate
                    .clone()
                    .add(index * intervals, "days");
                const newEndDate = originalEndDate
                    .clone()
                    .add(index * intervals, "days");

                if (formattedDate(newStartDate, formattedSingleEvents)) {
                    continue;
                }

                if (byday) {
                    const newStartDate = originalStartDate
                        .clone()
                        .add(index * intervals, "days");
                    const newEndDate = originalEndDate
                        .clone()
                        .add(index * intervals, "days");
                    const daysArray = byday.split(",").map((day) => getDayName(day));
                    if (daysArray.includes(newStartDate.day())) {
                        events.push(calculateNewEvent(newStartDate, newEndDate));
                    }
                } else {
                    events.push(calculateNewEvent(newStartDate, newEndDate));
                }
            } else if (unit === "weekly") {
                if (byday) {
                    const newStartDate = originalStartDate
                        .clone()
                        .add(index * intervals, "days");
                    const newEndDate = originalEndDate
                        .clone()
                        .add(index * intervals, "days");
                    if (formattedDate(newStartDate, formattedSingleEvents)) {
                        continue;
                    }
                    const daysArray = byday.split(",").map((day) => getDayName(day));
                    if (daysArray.includes(newStartDate.day())) {
                        events.push(calculateNewEvent(newStartDate, newEndDate));
                    }
                } else {
                    const newStartDate = originalStartDate
                        .clone()
                        .add(index * intervals, "weeks");
                    const newEndDate = originalEndDate
                        .clone()
                        .add(index * intervals, "weeks");
                    if (formattedDate(newStartDate, formattedSingleEvents)) {
                        continue;
                    }
                    events.push(calculateNewEvent(newStartDate, newEndDate));
                }
            } else if (unit === "monthly") {
                const newStartDate = originalStartDate
                    .clone()
                    .add(index * intervals, "months");
                if (formattedDate(newStartDate, formattedSingleEvents)) {
                    continue;
                }
                const baseDate = newStartDate.clone();

                if (
                    !byday &&
                    !/^[\d,]+$/.test(customMonth) &&
                    isNaN(parseInt(customMonth))
                ) {
                    const [week, day] = customMonth.match(/(-?\d+)(\w+)/).slice(1);
                    let weekIndex = parseInt(week, 10), targetDay = baseDate.clone().day(day);

                    if (weekIndex < 0) {
                        targetDay = baseDate.clone().endOf("month").day(day).add((weekIndex + 1) * 7, "days");
                        targetDay.startOf('day');
                        if (targetDay.month() > baseDate.month()) targetDay.subtract(7, "days");
                    } else {
                        if (targetDay.month() < baseDate.month()) targetDay.add(7, "days");
                        targetDay.add((weekIndex - 1) * 7, "days");
                    }

                    if (targetDay.month() === baseDate.month() && !formattedDate(targetDay, formattedSingleEvents)) {
                        events.push({
                            ...event,
                            start: targetDay.toDate(),
                            end: targetDay.clone().add(originalEndDate.diff(originalStartDate)).toDate(),
                        });
                    }
                } else if (!byday && /^[\d,]+$/.test(customMonth)) {
                    const splitDays = customMonth?.split(",");
                    splitDays?.map((day) => {
                        baseDate.date(parseInt(day));
                        if (formattedDate(baseDate, formattedSingleEvents)) {
                            return;
                        }
                        events.push(
                            calculateNewEvent(
                                baseDate,
                                baseDate.clone().add(originalEndDate.diff(originalStartDate))
                            )
                        );
                    });
                } else if (byday) {
                    const newStartDate = originalStartDate
                        .clone()
                        .add(index * intervals, "days");
                    const newEndDate = originalEndDate
                        .clone()
                        .add(index * intervals, "days");
                    if (formattedDate(newStartDate, formattedSingleEvents)) {
                        continue;
                    }
                    const daysArray = byday.split(",").map((day) => getDayName(day));
                    if (daysArray.includes(newStartDate.day())) {
                        events.push(calculateNewEvent(newStartDate, newEndDate));
                    }
                } else {
                    baseDate.date(parseInt(customMonth));
                    if (formattedDate(baseDate, formattedSingleEvents)) {
                        continue;
                    }
                    events.push(
                        calculateNewEvent(
                            baseDate,
                            baseDate.clone().add(originalEndDate.diff(originalStartDate))
                        )
                    );
                }
            } else if (unit === "yearly") {
                const newStartDate = originalStartDate
                    .clone()
                    .add(index * intervals, "year");
                const newEndDate = originalEndDate
                    .clone()
                    .add(index * intervals, "year");
                if (formattedDate(newStartDate, formattedSingleEvents)) {
                    continue;
                }
                if (bymonth) {
                    newStartDate.month(parseInt(bymonth) - 1);
                    newEndDate.month(parseInt(bymonth) - 1);
                }

                const numericPattern = /(?=.*\d)(?=.*[A-Za-z])/;
                const onlyNumericPattern = /^\d+$/;
                if (numericPattern.test(customMonth)) {
                    const baseDate = newStartDate.clone();
                    const [week, day] = customMonth.match(/(-?\d+)(\w+)/).slice(1);
                    let weekIndex = parseInt(week, 10), targetDay = baseDate.clone().day(day);
                    if (weekIndex < 0) {
                        targetDay = baseDate.clone().endOf("month").day(day).add((weekIndex + 1) * 7, "days");
                        targetDay.startOf('day');
                        if (targetDay.month() > baseDate.month()) targetDay.subtract(7, "days");
                    } else {
                        if (targetDay.month() < baseDate.month()) targetDay.add(7, "days");
                        targetDay.add((weekIndex - 1) * 7, "days");
                    }
                    if (targetDay.month() === baseDate.month() && !formattedDate(targetDay, formattedSingleEvents)) {
                        events.push({
                            ...event,
                            start: targetDay.toDate(),
                            end: targetDay.clone().add(originalEndDate.diff(originalStartDate)).toDate(),
                        });
                    }
                } else if (onlyNumericPattern.test(customMonth)) {
                    newStartDate.date(parseInt(customMonth));
                    newEndDate.date(parseInt(customMonth));
                    if (formattedDate(newStartDate, formattedSingleEvents)) {
                        continue;
                    }
                    events.push(calculateNewEvent(newStartDate, newEndDate));
                } else {
                    events.push(calculateNewEvent(newStartDate, newEndDate));
                }
            }
        }

        return events;
    } catch (error) {
        return [{ ...event }];
    }
};