import React, { use, useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import { shuffleAndCheckMatch } from "../helper/calendarHelper.js";

const defaultOptions = [
    { value: "never", label: "Does not repeat" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly on" },
    { value: "monthly", label: "Monthly on" },
    { value: "monthly_last", label: "Monthly on" },
    { value: "yearly", label: "Annually on" },
    { value: "every_week", label: "Every Weekday (Monday to Friday)" },
    { value: "custom", label: "Custom..." },
];

const defaultCustomWeek = [
    { label: "S", value: "SU" },
    { label: "M", value: "MO" },
    { label: "T", value: "TU" },
    { label: "W", value: "WE" },
    { label: "T", value: "TH" },
    { label: "F", value: "FR" },
    { label: "S", value: "SA" },
];

const RepeatEvent = ({onRepeatStateChange, onCustomRepeatStateChange, fromDate}) => {
    // State Managements
    const [date, setDate] = useState(new Date());
    const [editvalue, setEditvalue] = useState();
    const [repeatEvent, setRepeatEvent] = useState('');
    const [customRepeatEvent, setCustomRepeatEvent] = useState('');
    const [endDate, setEndDate] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [customWeek] = useState(defaultCustomWeek);
    const [repeatEvery, setRepeatEvery] = useState("1");
    const [endOption, setEndOption] = useState("never");
    const [occurrences, setOccurrences] = useState("13");
    const [repeatUnit, setRepeatUnit] = useState("weekly");
    const [selectedDays, setSelectedDays] = useState([]);
    const [options, setOptions] = useState(defaultOptions);
    const [selectedDateValue, setSelectedDateValue] = useState({});
    const [customSelectedMonth, setCustomSelectedMonth] = useState({ label: "", value: "" });
    const [showRepeatEventSelect, setShowRepeatEventSelect] = useState(false);
    const [customMonth, setCustomMonth] = useState([{ label: "", value: "" }]);
    const [selectionsOptions, setSelectionsOptions] = useState(defaultOptions[0]);

    const dayToString = {
        1: "first",
        2: "second",
        3: "third",
        4: "fourth",
        5: "last",
    };

    useEffect(() => {
        onRepeatStateChange(repeatEvent);
        onCustomRepeatStateChange(customRepeatEvent);
    },[repeatEvent, customRepeatEvent]);

    useEffect(() => {
        if (fromDate) {
            setDate(fromDate);
        }
    },[fromDate]);
    
    useEffect(() => {
        if (!date || !options) return;

        let formattedDate = moment(date, "LL").format("YYYY-MM-DD");
        if (date && typeof date === 'string' && (date?.split('/')).length > 1) {
            formattedDate = moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
        }

        const newDate = new Date(formattedDate);
        // Pre-compute values.
        const year = newDate.getFullYear();
        const month = newDate.getMonth();
        const day = newDate.getDate();
        const dayOfWeek = moment(formattedDate).format("dddd");
        const momentDate = moment({ year, month, day });
        const dayOfWeekNumber = Math.ceil(momentDate.date() / 7);
        const weekOfMonth = dayToString?.[dayOfWeekNumber];
        const monthOfDay = moment(formattedDate).format("MMMM D");

        let filterOptions = options;
        if (dayOfWeekNumber === 5) {
            filterOptions = options.filter((option) => option.value !== 'monthly_last')
        }

        // Update Dropdown Label Name Based on Selected Date.
        const updatedOptions = filterOptions?.map(
            (option: { label: string; value: string }): any => {
                switch (option.value) {
                    case "weekly":
                        setSelectedDateValue((prev) => ({
                            ...prev,
                            [option.value]: dayOfWeek,
                        }));
                        return { ...option, label: `Weekly on ${dayOfWeek}` };

                    case "monthly":
                        setSelectedDateValue((prev) => ({
                            ...prev,
                            [option.value]: `${dayOfWeekNumber === 5 ? -1 : dayOfWeekNumber
                                }${dayOfWeek}`,
                        }));

                        const isLastDayOfWeek = dayOfWeekNumber === 5;
                        const label = isLastDayOfWeek
                            ? `Monthly on the last ${dayOfWeek}`
                            : `Monthly on the ${weekOfMonth} ${dayOfWeek}`;
                        return {
                            ...option,
                            label,
                        };

                    case "monthly_last":
                        setSelectedDateValue((prev) => ({
                            ...prev,
                            [option.value]: `-1${dayOfWeek}`,
                        }));
                        return { ...option, label: `Monthly on the last ${dayOfWeek}` };

                    case "yearly":
                        return { ...option, label: `Annually on ${monthOfDay}` };

                    case "every_week":
                        setSelectedDateValue((prev) => ({
                            ...prev,
                            [option.value]: "MO,TU,WE,TH,FR",
                        }));
                        return { ...option, label: "Every Weekday (Monday to Friday)" };

                    default:
                        return option;
                }
            }
        );

        // Update custom month options and selected value.
        const customMonthOptions = [
            { label: `Monthly on day ${day}`, value: `${day}` },
            {
                label: `Monthly on the ${weekOfMonth} ${dayOfWeek}`,
                value: `${dayOfWeekNumber === 5 ? -1 : dayOfWeekNumber}${dayOfWeek}`,
            },
        ];
        const formatDay = dayOfWeek?.slice(0, 2);
        setSelectedDays([formatDay?.toUpperCase()]);
        setCustomMonth(customMonthOptions);
        setCustomSelectedMonth(customMonthOptions[0]);
        setEndDate(formattedDate);
        setOptions(updatedOptions);
    }, [date]);

    useEffect(() => {
        if (repeatEvent) {
            const spiltEvent = repeatEvent?.split("_");
            options?.map((option) => {
                if (option?.value === spiltEvent[0] && !shuffleAndCheckMatch(spiltEvent?.[1]) && spiltEvent[0] !== 'custom') {
                    setSelectionsOptions(option);
                } else if (shuffleAndCheckMatch(spiltEvent?.[1]) && option?.value === 'every_week') {
                    setSelectionsOptions(option);
                }
            });
        } else {
            setSelectionsOptions(options[0]);
        }
    }, [repeatEvent, options, editvalue]);

    const toggleDay = (day) => {
        setSelectedDays((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };

    const handleOnchange = (event) => {
        if (event?.value) {
            if (event.value === "custom") {
                setIsOpen(true);
            } else {
                setIsOpen(false);
                setCustomRepeatEvent('');
            }

            const formattedEvent = `${event?.value}_${selectedDateValue[event?.value] || ""
                }`;

            if (event?.value === "monthly_last") {
                setRepeatEvent(`monthly_${selectedDateValue[event?.value] || ""}`);
            } else if (event?.value === "every_week") {
                setRepeatEvent(`weekly_${selectedDateValue[event?.value] || ""}`);
            } else {
                setRepeatEvent(formattedEvent);
            }
            setSelectionsOptions(event);
        }
    };

    const handleCustomMonth = (event) => {
        if (event?.value) {
            setCustomSelectedMonth(event);
        }
    };

    const handleCustomOnchange = (event) => {
        const { name, value } = event?.target;
        const stateMap: { [key: string]: Function } = {
            repeatEvery: setRepeatEvery,
            repeatUnit: setRepeatUnit,
            occurrences: setOccurrences,
            endOption: setEndOption,
            endDate: setEndDate,
        };
        // Check if the name exists in the stateMap and update accordingly
        if (stateMap[name]) {
            stateMap[name](value);
        }
    };

    useEffect(() => {
        if (selectionsOptions?.value === 'custom') {
            let formatCustomData = Number(repeatEvery) > 1 ? `_interval~${repeatEvery}` : '';
            formatCustomData += repeatUnit ? `_unit~${repeatUnit}` : '';
            formatCustomData += repeatUnit === 'weekly' && selectedDays?.length ? `_byday~${selectedDays}` : '';
            formatCustomData += endOption === 'on' && endDate ? `_endDate~${endDate}` : '';
            formatCustomData += endOption === 'after' && occurrences ? `_count~${occurrences}` : '';
            formatCustomData += repeatUnit === 'monthly' && customSelectedMonth?.value ? `_customMonth~${customSelectedMonth?.value}` : '';
            setCustomRepeatEvent(formatCustomData)
        }
    }, [selectedDays, repeatEvery, repeatUnit, occurrences, endOption, endDate, customSelectedMonth, selectionsOptions]);

    useEffect(() => {

        if (!editvalue || !customRepeatEvent) return;
        let formattedDate = moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
        if (formattedDate === "Invalid date") {
            formattedDate = moment(date, "LL").format("YYYY-MM-DD");
        }

        const newDate = new Date(formattedDate);

        // Pre-compute values.
        const year = newDate.getFullYear();
        const month = newDate.getMonth();
        const day = newDate.getDate();
        const dayOfWeek = moment(formattedDate).format("dddd");
        const momentDate = moment({ year, month, day });
        const dayOfWeekNumber = Math.ceil(momentDate.date() / 7);
        const weekOfMonth = dayToString?.[dayOfWeekNumber];

        const customMonthOptions = [
            { label: `Monthly on day ${day}`, value: `${day}` },
            {
                label: `Monthly on the ${weekOfMonth} ${dayOfWeek}`,
                value: `${dayOfWeekNumber === 5 ? -1 : dayOfWeekNumber}${dayOfWeek}`,
            },
        ];

        const parseCustomRepeatEvent = () => {
            const customRepeatObject = {};

            const eventPairs = customRepeatEvent
                .split("_")
                .filter(Boolean)
                .map((item) => {
                    const [key, value] = item.split("~");
                    return key && value ? { [key]: value } : null;
                })
                .filter(Boolean);

            eventPairs.forEach((pair) => {
                if (pair) {
                    const [[key, value]] = Object.entries(pair);
                    customRepeatObject[key] = value;
                }
            });

            return customRepeatObject;
        };

        const updateEventSettings = (settings) => {
            const { unit, byday, count, endDate, interval, customMonth } = settings;

            if (unit) setRepeatUnit(unit);
            if (byday) setSelectedDays(byday.split(","));
            if (count) { setEndOption('after'); setOccurrences(count) };
            if (endDate) { setEndOption('on'); setEndDate(endDate) };
            if (interval) setRepeatEvery(interval);


            if (customMonth) {
                let selectedMonth = customMonthOptions?.find(
                    (data: { value: string }) => data.value === customMonth
                );
                if (selectedMonth) {
                    setCustomSelectedMonth(selectedMonth);
                    setSelectionsOptions(selectedMonth);
                    setOptions((prevOptions) => {
                        const lastIndex = prevOptions.length - 1;
                        const lastItem = prevOptions[lastIndex];
                        const mainItems = prevOptions.slice(0, lastIndex);

                        // Create array with new item
                        const newArray = [...mainItems, selectedMonth, lastItem];

                        // Filter duplicates based on value property
                        return newArray.filter((item, index, self) =>
                            index === self.findIndex(t => t.label === item.label)
                        );
                    });
                }
            } else if (unit === 'daily') {
                options?.find((option) => {
                    if (option.value === unit) {
                        setSelectionsOptions(option);
                    }
                });
            } else {
                options?.find((option) => {
                    if (option.value === 'custom') {
                        setSelectionsOptions(option);
                    }
                })
            }
        };

        const customRepeatObject = parseCustomRepeatEvent();
        updateEventSettings(customRepeatObject);
    }, [editvalue]);

    return (
        <div>
            <div className={showRepeatEventSelect ? "d-none" : "mx-6 my-1"}>
                <button
                    className="fw-bold border-0 bg-transparent repet-event-btn"
                    type="button"
                    onClick={() => {
                        setShowRepeatEventSelect((preSelect) => !preSelect);
                    }}
                >
                    {selectionsOptions?.label}
                </button>
            </div>

            {showRepeatEventSelect ? (
                <div className={"mx-4 my-3 flex"}>
                    <Select
                        name="repeatEvent"
                        value={selectionsOptions}
                        onChange={(event) => handleOnchange(event)}
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="select"
                        menuPlacement="bottom"
                        styles={{
                            control: (base) => ({
                                ...base,
                                borderRadius: "10px",
                                border: "1px solid rgb(233, 233, 233)",
                                boxShadow: "none",
                                "&:hover": {
                                    border: "1px solid rgb(233, 233, 233)",
                                },
                            }),
                            menu: (base) => ({
                                ...base,
                                border: "none",
                            }),
                            option: (base) => ({
                                ...base,
                            }),
                        }}
                    />
                </div>
            ) : (
                ""
            )}

            {/* Popup Modal */}
            {isOpen && (
                <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex align-items-center justify-content-center" style={{ zIndex: 1050 }}>
                    <div className="bg-white rounded shadow-lg p-4 w-24rem position-relative">
                        <h2 className="h5 fw-semibold mb-3">Custom recurrence</h2>
                        <div className="mb-3">
                            <label className="form-label">Repeat every</label>  
                            <div className="d-flex align-items-center gap-2">
                                <input
                                    type="number"
                                    name="repeatEvery"
                                    value={repeatEvery}
                                    onChange={(e) => handleCustomOnchange(e)}
                                    className="w-16 p-2  occurence-input outline-0"
                                    min="1"
                                />
                                <select
                                    name="repeatUnit"
                                    value={repeatUnit}
                                    onChange={(e) => handleCustomOnchange(e)}
                                    className="form-select w-auto"
                                >
                                    <option value="daily">Day</option>
                                    <option value="weekly">Week</option>
                                    <option value="monthly">Month</option>
                                    <option value="yearly">Year</option>
                                </select>
                            </div>
                        </div>
                        {repeatUnit === "weekly" ? (
                            <div className="mb-3">
                                <label className="form-label">Repeat on</label>
                                <div className="d-flex gap-2">
                                    {customWeek?.map((day, idx) => (
                                        <button
                                            key={idx}
                                            onClick={() => toggleDay(day?.value)}
                                            className={`btn btn-sm rounded-circle ${selectedDays.includes(day?.value)
                                                ? "btn-primary text-white"
                                                : "btn-light text-dark"
                                                }`}
                                        >
                                            {day?.label}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ) : null}

                        {repeatUnit === "monthly" ? (
                            <div className="mb-3 w-75">
                                <Select
                                    name="customRepeatEvent"
                                    defaultValue={customMonth[0]}
                                    value={customSelectedMonth}
                                    onChange={handleCustomMonth}
                                    options={customMonth}
                                    className="form-select"
                                    placeholder="Select"
                                    menuPlacement="bottom"
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderRadius: "0.375rem",
                                            border: "1px solid #e9e9e9",
                                            boxShadow: "none",
                                            "&:hover": { border: "1px solid #e9e9e9" },
                                        }),
                                        menu: (base) => ({ ...base, border: "none" }),
                                        option: (base) => ({ ...base, border: "none" }),
                                    }}
                                />
                            </div>
                        ) : null}

                        <div className="mb-3">
                            <label className="form-label">Ends</label>
                            <div className="d-flex flex-column gap-2">
                                <div className="form-check">
                                    <input
                                        name="endOption"
                                        type="radio"
                                        id="never"
                                        value="never"
                                        checked={endOption === "never"}
                                        onChange={(e) => handleCustomOnchange(e)}
                                        className="form-check-input"
                                    />
                                    <label htmlFor="never" className="form-check-label">Never</label>
                                </div>
                                <div className="form-check gap-2 form-check d-flex align-items-center">
                                    <input
                                        name="endOption"
                                        type="radio"
                                        id="on"
                                        value="on"
                                        checked={endOption === "on"}
                                        onChange={(e) => handleCustomOnchange(e)}
                                        className="form-check-input"
                                    />
                                    <label htmlFor="on" className="form-check-label">On</label>
                                    <input
                                        name="endDate"
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => handleCustomOnchange(e)}
                                        className={`occurence-input d-inline-block ms-2 w-auto ${endOption !== "on" && "opacity-50 pointer-events-none"
                                            }`}
                                        disabled={endOption !== "on"}
                                    />
                                </div>
                                <div className="form-check gap-2 form-check d-flex align-items-center">
                                    <input
                                        name="endOption"
                                        type="radio"
                                        id="after"
                                        value="after"
                                        checked={endOption === "after"}
                                        onChange={(e) => handleCustomOnchange(e)}
                                        className="form-check-input"
                                    />
                                    <label htmlFor="after" className="form-check-label">After</label>
                                    <input
                                        name="occurrences"
                                        type="number"
                                        value={occurrences}
                                        onChange={(e) => handleCustomOnchange(e)}
                                        className={`occurence-input d-inline-block ms-2 w-16 ${endOption !== "after" && "opacity-50 pointer-events-none"
                                            }`}
                                        disabled={endOption !== "after"}
                                        min="1"
                                    />
                                    <span className="ms-1">occurrences</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end gap-2">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="occurence-can-btn"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="btn gradient-bg occurence-done-btn"
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default RepeatEvent;
