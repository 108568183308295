export const icon = {
    pdf_svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#F15642'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48   c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z    M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"/>
            <path style={{fill:'#FFFFFF'}} d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296   c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"/>
            <path style={{fill:'#FFFFFF'}} d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68   h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912   c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    jpg_svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#50BEE8'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M141.968,303.152c0-10.752,16.896-10.752,16.896,0v50.528c0,20.096-9.6,32.256-31.728,32.256   c-10.88,0-19.952-2.96-27.888-13.184c-6.528-7.808,5.76-19.056,12.416-10.88c5.376,6.656,11.136,8.192,16.752,7.936   c7.152-0.256,13.44-3.472,13.568-16.128v-50.528H141.968z"/>
            <path style={{fill:'#FFFFFF'}} d="M181.344,303.152c0-4.224,3.328-8.832,8.704-8.832H219.6c16.64,0,31.616,11.136,31.616,32.48   c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816   L181.344,303.152L181.344,303.152z M198.24,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504   c0-8.944-6.784-16.368-15.36-16.368H198.24z"/>
            <path style={{fill:'#FFFFFF'}} d="M342.576,374.16c-9.088,7.552-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.344-45.936-45.808   c0-25.824,20.096-45.904,47.072-45.904c10.112,0,21.232,3.44,29.168,11.248c7.792,7.664-3.456,19.056-11.12,12.288   c-4.736-4.608-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.464,29.296,30.464   c7.792,0,14.448-2.32,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.616,0-15.616h25.584c4.736,0,9.072,3.584,9.072,7.552v27.248   C345.76,369.568,344.752,371.712,342.576,374.16z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#F7B84E'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M96.816,314.656c2.944-24.816,40.416-29.28,58.08-15.712c8.704,7.024-0.512,18.16-8.192,12.528   c-9.472-6.016-30.96-8.832-33.648,4.464c-3.456,20.992,52.192,8.976,51.312,42.992c-0.896,32.496-47.984,33.264-65.648,18.672   c-4.224-3.44-4.096-9.056-1.792-12.528c3.328-3.312,7.04-4.464,11.392-0.896c10.48,7.168,37.488,12.544,39.392-5.648   C146.064,339.616,92.848,351.008,96.816,314.656z"/>
            <path style={{fill:'#FFFFFF'}} d="M209.12,378.256l-33.776-70.752c-4.992-10.112,10.112-18.416,15.728-7.808l11.392,25.712   l14.704,33.776l14.448-33.776l11.392-25.712c5.12-9.712,19.952-3.584,15.616,7.04L226,378.256   C223.056,386.32,213.984,388.224,209.12,378.256z"/>
            <path style={{fill:'#FFFFFF'}} d="M345.76,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808   c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.792,7.664-3.456,19.056-11.12,12.288   c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448   c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248   C348.96,369.552,347.936,371.712,345.76,374.16z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    doc_svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#50BEE8'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M92.576,384c-4.224,0-8.832-2.32-8.832-7.936v-72.656c0-4.608,4.608-7.936,8.832-7.936h29.296   c58.464,0,57.168,88.528,1.136,88.528H92.576z M100.64,311.072v57.312h21.232c34.544,0,36.064-57.312,0-57.312H100.64z"/>
            <path style={{fill:'#FFFFFF'}} d="M228,385.28c-23.664,1.024-48.24-14.72-48.24-46.064c0-31.472,24.56-46.944,48.24-46.944   c22.384,1.136,45.792,16.624,45.792,46.944C273.792,369.552,250.384,385.28,228,385.28z M226.592,308.912   c-14.336,0-29.936,10.112-29.936,30.32c0,20.096,15.616,30.336,29.936,30.336c14.72,0,30.448-10.24,30.448-30.336   C257.04,319.008,241.312,308.912,226.592,308.912z"/>
            <path style={{fill:'#FFFFFF'}} d="M288.848,339.088c0-24.688,15.488-45.92,44.912-45.92c11.136,0,19.968,3.328,29.296,11.392   c3.456,3.184,3.84,8.816,0.384,12.4c-3.456,3.056-8.704,2.688-11.776-0.384c-5.232-5.504-10.608-7.024-17.904-7.024   c-19.696,0-29.152,13.952-29.152,29.552c0,15.872,9.328,30.448,29.152,30.448c7.296,0,14.08-2.96,19.968-8.192   c3.952-3.072,9.456-1.552,11.76,1.536c2.048,2.816,3.056,7.552-1.408,12.016c-8.96,8.336-19.696,10-30.336,10   C302.8,384.912,288.848,363.776,288.848,339.088z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    png_svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#A066AA'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M92.816,303.152c0-4.224,3.312-8.848,8.688-8.848h29.568c16.624,0,31.6,11.136,31.6,32.496   c0,20.224-14.976,31.472-31.6,31.472H109.68v16.896c0,5.648-3.552,8.832-8.176,8.832c-4.224,0-8.688-3.184-8.688-8.832   C92.816,375.168,92.816,303.152,92.816,303.152z M109.68,310.432v31.856h21.376c8.56,0,15.344-7.552,15.344-15.488   c0-8.96-6.784-16.368-15.344-16.368L109.68,310.432L109.68,310.432z"/>
            <path style={{fill:'#FFFFFF'}} d="M178.976,304.432c0-4.624,1.024-9.088,7.68-9.088c4.592,0,5.632,1.152,9.072,4.464l42.336,52.976   v-49.632c0-4.224,3.696-8.848,8.064-8.848c4.608,0,9.072,4.624,9.072,8.848v72.016c0,5.648-3.456,7.792-6.784,8.832   c-4.464,0-6.656-1.024-10.352-4.464l-42.336-53.744v49.392c0,5.648-3.456,8.832-8.064,8.832s-8.704-3.184-8.704-8.832v-70.752   H178.976z"/>
            <path style={{fill:'#FFFFFF'}} d="M351.44,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808   c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288   c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448   c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248   C354.624,369.552,353.616,371.712,351.44,374.16z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    xls_svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#84BD5A'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M144.336,326.192l22.256-27.888c6.656-8.704,19.584,2.416,12.288,10.736   c-7.664,9.088-15.728,18.944-23.408,29.04l26.096,32.496c7.04,9.6-7.024,18.8-13.936,9.328l-23.552-30.192l-23.152,30.848   c-6.528,9.328-20.992-1.152-13.696-9.856l25.712-32.624c-8.064-10.112-15.872-19.952-23.664-29.04   c-8.048-9.6,6.912-19.44,12.8-10.464L144.336,326.192z"/>
            <path style={{fill:'#FFFFFF'}} d="M197.36,303.152c0-4.224,3.584-7.808,8.064-7.808c4.096,0,7.552,3.6,7.552,7.808v64.096h34.8   c12.528,0,12.8,16.752,0,16.752H205.44c-4.48,0-8.064-3.184-8.064-7.792v-73.056H197.36z"/>
            <path style={{fill:'#FFFFFF'}} d="M272.032,314.672c2.944-24.832,40.416-29.296,58.08-15.728c8.704,7.024-0.512,18.16-8.192,12.528   c-9.472-6-30.96-8.816-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,43.008c-0.896,32.496-47.968,33.248-65.632,18.672   c-4.24-3.456-4.096-9.072-1.792-12.544c3.328-3.312,7.024-4.464,11.392-0.88c10.48,7.152,37.488,12.528,39.392-5.648   C321.28,339.632,268.064,351.008,272.032,314.672z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    gif_svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#A066AA'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M199.84,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808   c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288   c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448   c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248   C203.024,369.552,202.016,371.712,199.84,374.16z"/>
            <path style={{fill:'#FFFFFF'}} d="M224.944,303.152c0-10.496,16.896-10.88,16.896,0v73.024c0,10.624-16.896,10.88-16.896,0V303.152z"/>
            <path style={{fill:'#FFFFFF'}} d="M281.12,312.096v20.336h32.608c4.608,0,9.216,4.608,9.216,9.088c0,4.224-4.608,7.664-9.216,7.664   H281.12v26.864c0,4.48-3.2,7.936-7.68,7.936c-5.632,0-9.072-3.456-9.072-7.936v-72.656c0-4.608,3.456-7.936,9.072-7.936h44.912   c5.632,0,8.96,3.328,8.96,7.936c0,4.096-3.328,8.688-8.96,8.688H281.12V312.096z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    text_svg:
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" version="1.1" id="Layer_1" viewBox="0 0 512 512" space="preserve">
        <path style={{fill:'#E2E5E7'}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
        <path style={{fill:'#B0B7BD'}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
        <polygon style={{fill:'#CAD1D8'}} points="480,224 384,128 480,128 "/>
        <path style={{fill:'#576D7E'}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"/>
        <g>
            <path style={{fill:'#FFFFFF'}} d="M132.784,311.472H110.4c-11.136,0-11.136-16.368,0-16.368h60.512c11.392,0,11.392,16.368,0,16.368   h-21.248v64.592c0,11.12-16.896,11.392-16.896,0v-64.592H132.784z"/>
            <path style={{fill:'#FFFFFF'}} d="M224.416,326.176l22.272-27.888c6.656-8.688,19.568,2.432,12.288,10.752   c-7.68,9.088-15.728,18.944-23.424,29.024l26.112,32.496c7.024,9.6-7.04,18.816-13.952,9.344l-23.536-30.192l-23.152,30.832   c-6.528,9.328-20.992-1.152-13.68-9.856l25.696-32.624c-8.048-10.096-15.856-19.936-23.664-29.024   c-8.064-9.6,6.912-19.44,12.784-10.48L224.416,326.176z"/>
            <path style={{fill:'#FFFFFF'}} d="M298.288,311.472H275.92c-11.136,0-11.136-16.368,0-16.368h60.496c11.392,0,11.392,16.368,0,16.368   h-21.232v64.592c0,11.12-16.896,11.392-16.896,0V311.472z"/>
        </g>
        <path style={{fill:'#CAD1D8'}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>,
    default_file_svg:
        <svg xmlns="http://www.w3.org/2000/svg" width="75px" height="75px" viewBox="0 0 1024 1024" className="icon" version="1.1"><path d="M853.333333 960H170.666667V64h469.333333l213.333333 213.333333z" fill="#90CAF9"/><path d="M821.333333 298.666667H618.666667V96z" fill="#E1F5FE"/></svg>

}