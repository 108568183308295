import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { logo } from '../../assets/svg/svg'
import Web3 from 'web3';
import Cookies from "universal-cookie";
import { getHostHelperContract } from '../../helper/contract-helper';
import { CompleteLoader } from '../../modals/common-alert';
import { Signature } from '../../modals/SignatureModal.js';
import { toast, Bounce } from 'react-toastify';
import { transactionAction } from '../../helper/chain-helper';
import { getGeneralSettings, timeZone } from '../../helper/settingsHelper.js';
import Loader from "../../components/Loader.js";

const cookies = new Cookies();
const accountSettings = [
    { label: "Save all sent emails", value: false  , id : 1, key: 'isSaveSent', type: 'checkBox'},
    { label: "Include Previous Messages", value: true  , id : 2, key: 'isIncludePrevious', type: 'checkBox' },
    { label: "Web2 Inbox", value: true  , id : 3, key: 'isWeb2Inbox', type: 'checkBox' },
    { label: "Group Email", value: true  , id : 4, key: 'isGrouped', type: 'checkBox' },
    { label: "Check Spelling", value: false  , id : 4, key: 'isSpellChecked', type: 'checkBox' },
    { label: "Time Zone", value: 'system' , id : 5, key: 'zone', type: 'select' },
];

const General = () => {
    const [settings, setSettings] = useState(accountSettings);
    const [isOpen, setIsOpen] = useState(false);
    const [allSettings, setAllSettings] = useState([]);
    const [account, setAccount] = useState('');
    const [loader , setLoader ] = useState(false);
    const [sectionLoader , setSectionLoader ] = useState(false);
    const [web3Value, setWeb3] = useState(null);
    const [user] = useState(cookies.get("userObject"));
    const dispatch = useDispatch();
    const [buttonText, setButtonText] = useState('Save Changes');
    const refreshSettings = useSelector((state) => state.refreshSettings);

    useEffect(() => {
        if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        window.ethereum
            .request({ method: 'eth_accounts' })
            .then(accounts => {
            if (accounts.length > 0) {
                setAccount(accounts[0]);
            }
            })
            .catch(err => console.error(err));
        window.ethereum.on('accountsChanged', accounts => {
            setAccount(accounts[0] || '');
        });
        } else {
            console.log('MetaMask is not installed');
        }
        fetchdata();
    }, []);

    async function fetchdata(){
        setSectionLoader(true);
        // Get all settings
        const helperContract = getHostHelperContract();
        const getSettings = await helperContract.methods.getSettings(user.name).call({ from: user.wallet });
        const generalSettings = await getGeneralSettings();
        const updatedSettings = [...settings].map(vakue => {
            return {
                ...vakue,
                value: generalSettings[vakue.key]
            }
        });
        setSettings(updatedSettings);
        setAllSettings(getSettings);
        setSectionLoader(false);
    }

    const handleChange = async (index) => {
        const updatedSettings = [...settings];
        updatedSettings[index].value = !updatedSettings[index].value;
        setSettings(updatedSettings);
    };

    const handleSelectChange = (index, newValue) => {
        const updatedSettings = [...settings];
        updatedSettings[index].value = newValue;
        setSettings(updatedSettings);
    };
    
    const saveGeneral = async () => {
        setLoader(true);
        setButtonText("Processing...");
        const options = settings.map((setting, index) => {
           return {
                key: setting.key, value: setting.value.toString()
           }
        });
        const helperContract = getHostHelperContract();  
        const functionParams = [ user.name, 'general', options];
        const hash = await transactionAction(helperContract , "updateSettings", functionParams , account);
        setButtonText('loading...');
        if(hash) {
            setButtonText("success");
            setButtonText("Saved");
            dispatch(  { type: "REFRESH_SETTINGS",  payload: !refreshSettings}  );
            toast.success("Successfully updated.", {
                position: "top-center",
                transition: Bounce,
            });
        }else{
            setButtonText("Save Changes")
            toast.error("Something went wrong.", {
                position: "top-center",
                transition: Bounce,
            });
        }
        setButtonText("Save Changes");
        await fetchdata();
        setLoader(false);
    }

    return <>
    {!sectionLoader ? (
        <div className='settings-json-container container'>  
            <div className='setting-json-render '>
                {settings.map((setting, index) => (
                    <div className="toggle-section-settings row mb-3" key={index}>
                    <div className="col-6">{setting.label}</div>
                    <div className="col-2"></div>
                    {setting.type === 'checkBox' ? (
                            
                            <label className="toggle-settings col-2">
                                <input
                                    type="checkbox"
                                    checked={setting.value}
                                    onChange={() => handleChange(index)}
                                />
                                <span className="slider round justify-content-end"></span>
                            </label>
                        ) : setting.type === 'select' ? (
                            <select
                                className="time-zone-select cursor-pointer col-6"
                                value={setting.value}
                                onChange={(e) => handleSelectChange(index, e.target.value)}
                            >
                                <option value={'system'}>System</option>
                                {timeZone.map((zone, zoneIndex) => (
                                    <option key={zoneIndex} value={zone.zoneName}>
                                        {zone.zoneName}
                                    </option>
                                ))}
                            </select>
                        ) : null}
                    </div>
                ))}
                <div className="toggle-section-settings">
                    <div>Signature</div>
                    <label className="toggle-settings">
                        <button className='sign-edit-btn cursor-pointer' onClick={() => { setIsOpen(true) }}><span className='mt-1'>{logo.edit_icon_light}</span></button>
                    </label>
                </div>
            </div>
            <div className='save-settings-btn mt-3' onClick={()=>{ saveGeneral() }}>
                <button>
                    <span>{buttonText}</span>
                </button>
            </div>  
        </div>
    ) : ( 
        <Loader normal={true}/>
    )}
        <Signature isOpen={isOpen} close={()=>{ setIsOpen(false); fetchdata() }} loader={setLoader} settings={allSettings} user={user} account={account} />
        <CompleteLoader isOpen={loader} />
    </>

}

export default General;