import React from 'react'
import { logo } from '../assets/svg/svg'

const CommonLayout = () => {
  return (
    <div className='initial-layout-start footer-auth'>

        <div className='dmail-logo'> 
                        {logo.dmail_logo_svg}
                        <h4 className="dmail-content-h4"> {logo.dmail_name_black_svg} </h4>
        </div>

        <div className='dmail-text'> 
              <span> Welcome to worlds first ever truly blockchain based decentralized email application! This is what it means to own and control your data! </span>        
        </div>
        
        <div className='btn-actions'> 
            <a className='btn-auth cursor-pointer' href="https://dmail.earth/terms-conditions.html" target="_blank" rel="noopener noreferrer"> Terms And Conditions </a>
            <a className='btn-auth cursor-pointer' href="https://dmail.earth/privacy-policy.html" target="_blank" rel="noopener noreferrer"> Privacy Policy </a>
            <a className='btn-auth cursor-pointer' href="https://dmail.earth/about_us.html" target="_blank" rel="noopener noreferrer"> Contact Us </a>
        </div>
        
    </div>
  )
}

export default CommonLayout
