import Web3 from 'web3';
import { log } from "../service/api-actions.js";
const web3 = new Web3(window.ethereum);

// Common transaction method for all the write operation
export const transactionAction = async (contract, functionName, functionParams, senderAccount) => {
    try {
        let gasLimit = undefined;
        // get estimated gas for the transaction
        await contract.methods[functionName](...functionParams).estimateGas({ from: senderAccount }).then(function(gasAmount){
            gasLimit =  gasAmount;
        })
        .catch(function(error){
            console.log("gasLimit error", error)
        });
        // Get current gas price from the network
        const gasPrice = await web3.eth.getGasPrice();
                
        // Send the transaction
        const transaction = await contract.methods[functionName](...functionParams).send({ from: senderAccount , gas: gasLimit ,gasPrice: gasPrice });
        
        // Get transaction receipt
        const receipt = await web3.eth.getTransactionReceipt(transaction.transactionHash);            
        
        // Extract transaction hash
        const txHash = receipt.transactionHash;        
        await log(txHash, 'transaction');
        return txHash;   
    } catch (error) {
        console.log("error", error)
        await log(JSON.stringify(error));
        return null;
    }
}


export const getEstimateGasFee = async(contract, functionName, functionParams, senderAccount)=> {
    const gasLimit =  await contract.methods[functionName](...functionParams).estimateGas({ from: senderAccount });
    return gasLimit;
}