// Function to operate the optional validation customizingly
export const optionalValidation = (obj, path, defaultValue = null) => {
  try {
    const properties = path.split(".");
    let result = obj;
    for (const prop of properties) {
      if (result === null || result === undefined) {
        return defaultValue;
      }
      result = result[prop];
    }
    return result !== undefined ? result : defaultValue;
  } catch (err) {
    return false
  }

}


export const validateTheWebReturedValues = (obj) => {

  const filteredData = {};
  for (const key in obj) {
    if (!isNaN(key)) continue; // Skip if the key is a number
    filteredData[key] = obj[key];
  }


  if (filteredData && filteredData["__length__"]) delete filteredData["__length__"];
  return filteredData;
}



export const getCurrentDate = () => {

  const currentDateValue = new Date();
  const formattedDateTime = currentDateValue.toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric'
  }) + ' ' + currentDateValue.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return formattedDateTime;
}


export function getFirstLetterUppercase(input) {
  try {
    return input.charAt(0).toUpperCase();
  } catch (error) {
    return input;
  }
}



export function formatListValueFromArray(existingFolderRecords, retrivedFromChain, type) {


  const existingFolderRecordsValue = existingFolderRecords.map((value) => JSON.parse(value));

  const retrivedFromChainValue = retrivedFromChain.map((value) => {
    value.emailType = type;
    return value;
  });

  const combinedArray = existingFolderRecordsValue.concat(retrivedFromChainValue);

  const finalArray = [];
  for (let data of combinedArray) {
    const hasId1AndEmailTypeTest = finalArray.some(obj => parseInt(obj.id) === parseInt(data.id) && obj.emailType === data.emailType);
    if (!hasId1AndEmailTypeTest) finalArray.push(data)
  }

  return finalArray;

}


export const returnStringJson = (email) => {

  const jsonString = JSON.stringify(email, (key, value) =>
    typeof value === 'bigint'
      ? value.toString() + 'n'
      : value
  );

  return jsonString;
}


export const convertToLocalDate = (UTCtime) => {

  try {
    if (!UTCtime) return "";
    const date = new Date(UTCtime);
    let timeZone = localStorage.getItem('timeZone') ?? 'system';
    const timeZoneToUse = timeZone === 'system' ? Intl.DateTimeFormat().resolvedOptions().timeZone : timeZone;

    const localTime = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZoneToUse,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(date);

    return localTime;

  } catch (error) {
    console.error('Error converting date:', error);
    return "";
  }
}

export const convertToLocalDateforWeb2 = (UTCtime) => {
  try {
    if (!UTCtime) return "";
    const utcDate = new Date(UTCtime.endsWith('Z') ? UTCtime : UTCtime + 'Z');
    let timeZone = localStorage.getItem('timeZone') ?? 'system';
    const timeZoneToUse = timeZone === 'system' ? Intl.DateTimeFormat().resolvedOptions().timeZone : timeZone;

    const localTime = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZoneToUse,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(utcDate);

    return localTime;

  } catch (error) {
    console.error('Error converting date:', error);
    return "";
  }
}