import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { getEventsByUser, adjustToUTCAndAddOffset, formatEventsDetails, mergeOverlappingEvents } from "../helper/calendarHelper.js";


const SlotCalendar = ({ fromDate, toDate, setFromDate, setToDate, setFromTime, setToTime, setVisible, setChecked, fromTime, toTime, guestList }) => {
    const localizer = momentLocalizer(moment);
    const [daypicker, setDaypicker] = useState(new Date());
    const [otherEvents, setOtherEvents] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState({});

    useEffect(() => {
        const getUsersEvents = async () => {
            if (guestList.length > 0) {
                const data = await getEventsByUser(guestList);
                const mappedData = data.map((event) => {
                    return {
                        title: '',
                        start: adjustToUTCAndAddOffset(event.fromTime),
                        end: adjustToUTCAndAddOffset(event.toTime),
                        list: event.list
                    };
                });
                const fromatedData = formatEventsDetails(mappedData);
                const mergedEvents = mergeOverlappingEvents(fromatedData);
                setOtherEvents(mergedEvents);
            } else {
                setOtherEvents([]);
            }
        };
        getUsersEvents();
    }, [guestList]);

    useEffect(() => {
        setDaypicker(new Date(fromDate));
    }, [fromDate])

    useEffect(() => {
        setDaypicker(new Date(toDate));
    }, [toDate])

    useEffect(() => {
        const startDate = new Date(fromDate);
        const endDate = new Date(toDate);

        const getFromTime = new Date(fromTime);
        const getTotime = new Date(fromTime);
        if (startDate.getTime() === endDate.getTime() && isNaN(getFromTime.getTime()) && isNaN(getTotime.getTime())) {
            const [fromHour, fromMinutePart] = fromTime.split(':');
            const [toHour, toMinutePart] = toTime.split(':');

            const fromMinutes = parseInt(fromMinutePart.substring(0, 2), 10);
            const toMinutes = parseInt(toMinutePart.substring(0, 2), 10);

            const fromPeriod = fromMinutePart.substring(3); // AM or PM
            const toPeriod = toMinutePart.substring(3); // AM or PM

            const fromHours24 = fromPeriod === 'PM' ? (parseInt(fromHour, 10) % 12) + 12 : parseInt(fromHour, 10);
            const toHours24 = toPeriod === 'PM' ? (parseInt(toHour, 10) % 12) + 12 : parseInt(toHour, 10);

            const start = moment(startDate)
                .set({ hour: fromHours24, minute: fromMinutes })
                .toDate();

            const end = moment(endDate)
                .set({ hour: toHours24, minute: toMinutes })
                .toDate();
            setSelectedSlots({ start, end });
        } else {
            setSelectedSlots({});
        }
    }, [fromDate, toDate, fromTime, toTime]);




    const CustomNavBar = ({ label }) => {
        return (
            <div className="calendar-nav-section pb-3 flex justify-content-between">
                <span className="calendar-nav-btn flex gap-3">
                    <span className='cursor-pointer' onClick={() => setDaypicker(new Date(daypicker.setDate(daypicker.getDate() - 1)))}><ChevronLeft /></span>
                    <span className='cursor-pointer' onClick={() => setDaypicker(new Date(daypicker.setDate(daypicker.getDate() + 1)))}><ChevronRight /></span>
                </span>
                <span className="title">{label}</span>
            </div>
        )
    }
    const selectableEvent = (slotInfo) => {
        const start = slotInfo.start;
        const end = slotInfo.end;
        setVisible(true);
        setChecked(false);
        // setSelectedSlots({ start, end });
        setFromDate(moment(start).startOf('day'));
        setToDate(moment(end).startOf('day'));
        setFromTime(moment(start).format('HH:mm A'));
        setToTime(moment(end).format('HH:mm A'));


    };

    const slotStyleGetter = (date) => {
        if (selectedSlots) {
            const { start, end } = selectedSlots;
            const momentStart = moment(start);
            const momentEnd = moment(end);
            const momentDate = moment(date);

            if (momentDate.isBetween(momentStart, momentEnd, null, '[)')) {
                return {
                    className: 'selected-slot',
                    style: {
                        backgroundColor: '#ddd',
                        opacity: 1,
                        border: 'none',
                        width: '100%',
                        position: 'relative',
                    },
                };
            }
        }
        return {};
    };

    return (
        <div>
            <Calendar
                localizer={localizer}
                events={otherEvents}
                startAccessor="start"
                endAccessor="end"
                views={['day']}
                selectable
                date={daypicker}
                defaultView="day"
                // step={1}
                // timeslots={60}
                step={30}
                timeslots={2}
                onSelectSlot={(slot) => { selectableEvent(slot) }}
                slotPropGetter={slotStyleGetter}
                onNavigate={(newDate) => setDaypicker(new Date(newDate))}
                components={{
                    toolbar: (props) => (
                        <>
                            <CustomNavBar label={props.label} />
                        </>
                    ),
                }}
            />
        </div>
    );

}

export default SlotCalendar;