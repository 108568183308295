import React, { useState, useEffect } from 'react';
import CommonMessage from "../components/CommonMessage";
import { web3AccountCheck } from '../helper/web3helper';
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import { formatMessageList } from '../helper/dataFormatHelper';
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from 'react-redux';
import { getDecryptedContentFromIndexedDb } from "../db/indexed-helper";
import { getHostContract, getHostHelperContract } from "../helper/contract-helper.js";

const cookies = new Cookies();

const Folders = () => {
  const [user] = useState(cookies.get("userObject"));
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [web3Contract, setWeb3Contract] = useState('');
  const [allBoxList, setAllBoxList] = useState([]);
  const [readList, setReadList] = useState([]);
  const [unreadList, setUnreadList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rendered, setRendered] = useState(false);
  const [renderTime, setRenderTime] = useState(new Date());
  const userName = user.name;
  const getFolderIndex = useSelector((state) => state.folderIndex);

  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3 , setAccount);
    } else {
      console.log('MetaMask is not installed');
    }
  }, []);

  useEffect(() => {
    if(account && web3Value){
        const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
        setWeb3Contract(contractInstance);
    }
  }, [web3Value, account]);


  useEffect(() => {
    if(web3Contract){
        setEmail(true);
    }
  }, [web3Contract,getFolderIndex]);



  useEffect(() => {
    if(web3Contract){
        setEmail();
    }
  }, []);


  const reRender = () => {
    setEmail();
  }

  
  async function setEmail(loaderRequired = true) {

    try {

      if (!web3Contract) return;

      if (loaderRequired) {
        setLoader(true);
        setRendered(false);
      }

      const hostContract = getHostContract();
      const helperContract = getHostHelperContract();
      const folderRecords = await hostContract.methods.getEmailList(userName).call({ from: user.wallet });
      const sentfolderRecords = await hostContract.methods.getSentEmailList(userName).call({ from: user.wallet });
      const result = await helperContract.methods.getAttributes(userName).call({ from: user.wallet });
      const folderAttributes = result.filter(value => value.attType == 'folder' && value.key == `inbox-folder-${parseInt(localStorage.getItem('selectedFolderId'))}`).map(value => value.id);
      const sentfolderAttributes = result.filter(value => value.attType == 'folder' && value.key == `sent-folder-${parseInt(localStorage.getItem('selectedFolderId'))}`).map(value => value.id);
      const formattedResult = folderRecords
        .filter(email => email.senderName && folderAttributes.includes(email.id))
        .map(email => ({
          id: parseInt(email.id),
          subject: email.subject,
          encryptedData: email.encryptedData,
          created_at: email.receivedDate,
          isStarred: email.isStarred,
          sender: email.senderName,
          isRead: email.isRead,
          header: email.emailDetails,
          isCC: email.isCC,
          isBCC: email.isBCC,
          emailType: 'inbox',
          ...email
        }));

      const sentformattedResult = sentfolderRecords
        .filter(email => email.receiver && sentfolderAttributes.includes(email.id))
        .map(email => ({
          id: parseInt(email.id),
          subject: email.subject,
          encryptedData: email.encryptedData,
          created_at: email.receivedDate,
          isStarred: false,
          sender: email.sender,
          isRead: true,
          header: email.receiver,
          emailType: 'sent',
          ...email
        }));
      const filteredValue = formattedResult.concat(sentformattedResult);
      for(let list of filteredValue){
        const decryptedMessage = await getDecryptedContentFromIndexedDb(parseInt(list.id), 'folders');
        list.decryptedMessage = decryptedMessage 
      }
      const list = await formatMessageList(filteredValue.reverse()); 
      setAllBoxList(list.allList.sort((a, b) => new Date(b.date) - new Date(a.date)));
      setReadList(list.readList.sort((a, b) => new Date(b.date) - new Date(a.date))); 
      setUnreadList(list.unReadList.sort((a, b) => new Date(b.date) - new Date(a.date)));

      if (loaderRequired) {
        setRendered(true);
      }

    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  }

  useEffect(() => {
    if(rendered){
      setLoader(false)
    }
  }, [rendered]);


  return (
    <div className="inbox-container">
        <CommonMessage messageList={allBoxList} pageType={"Folders"} unreadList={unreadList} loader={loader} readList={readList} reRender={reRender}/>
    </div>
  );
}


export default Folders
