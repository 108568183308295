import React, { useState, useEffect } from 'react';
import "../components/styles/profile.css"
import { logo } from '../assets/svg/svg.js';
import Cookies from "universal-cookie";
import { getHostContract } from '../helper/contract-helper.js';
import { web3AccountCheck } from '../helper/web3helper.js';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { deleteDatabase } from '../db/db-helper.js';
import Loader from './Loader.js';
import { getFirstLetterUppercase } from '../helper/object-validation-helper.js';
import { createWeb2User } from "../service/api-actions"
import config from "../web3/web3Config.json";
import balance from "../assets/images/balance.png";
import { log } from "../service/api-actions.js";
import { getBrowserDetails } from '../helper/userActivity.js';
import { login } from "../service/api-actions";

const cookies = new Cookies();

const Profile = (props) => {

  const history = useNavigate(); // Get the history object
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [user] = useState(cookies.get("userObject"));
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [downValue, setDown] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log('MetaMask is not installed');
    }
  }, []);


  useEffect(() => {

    async function getUserDetailsForWallet() {

      setLoader(true);

      const contract = getHostContract();
      if (account) {
        const getUserDetailsForWallet = await contract.methods.getUserDetailsForWallet().call({ from: account });
        let loginUserIndex = getUserDetailsForWallet.findIndex(Item => Item.domain == user.name)
        let loginUser = _.pullAt(getUserDetailsForWallet, loginUserIndex)
        setEmailAddresses(_.concat(loginUser, getUserDetailsForWallet));
      }

      setLoader(false);
    }

    getUserDetailsForWallet();

  }, [account]);


  function handleEmailChange(event, index) {
    setSelectedEmail(event.target.value);
    switchAccount(emailAddresses[index]);
  }

  async function switchAccount(user) {
    setLoader(true);
    const userObject = { name: user.domain, wallet: user.walletAddress, publicKey: user.publicKey };
    const getToken = await login(user.domain, user.publicKey);
    if (getToken && getToken.isAuth && getToken.token) {
      cookies.set("userObject", userObject, { path: "/" });
      cookies.set("accessToken", getToken.token, { path: "/" });
      deleteDatabase();
      await createWeb2User(user.domain);
      await log(await getBrowserDetails(), 'login');
      window.location.reload();
    } else {
      window.open('/', '_self');
    }
  }

  return (

    <>
      {loader && <div className='profile-container'> <Loader /> </div>}
      {!loader &&
        <div className='profile-container' style={{ zIndex: '1000' }}>
          <div className='close-icon-profile'>
            <div className='cursor-pointer' onClick={props.handleProfile} >  {logo.close_svg} </div>
          </div>
          <div className='header-data-profile'>
            <div className='flex items-center gap-20'>
              {/* <div> {logo.svg_notification} </div> */}
              <div className="profile-pic-profile-component">{getFirstLetterUppercase(user.name.split("@")[0])}</div>
              <div className='profile-name-username-section'>
                <span className='profile-welcome' > Welcome </span>
                <span className='profile-welcome-name'>{user && user.name.split("@")[0]}</span>
                <span className='profile-welcome profile-welcome-email'>{user && user.name}</span>
              </div>
            </div>
            <div className='dot-menu-profile'>
              <div> {logo.svg_menu_dot_pofile} </div>
            </div>
          </div>

          <div className='profile-address-switch-container'>
            <span className='text-span-switch-email-heading'> Switch Email Address </span>
            {emailAddresses.length > 0 && <>
              {emailAddresses.map((email, index) => {
                if (!email) return ""
                return (
                  <div className='row-content-user-address-profile flex' key={index}>
                    <div className='user-name-section switch-account col-10'>
                      <div className='address-profile-img '>
                        <div className='user-profile-pic'>{getFirstLetterUppercase(email.domain.split('@')[0])} </div>
                      </div>
                      <span className='address swich-account-address' >  {email.domain.split('@')[0]} <br />
                        <span className='profile-welcome w-full' >  {email.domain}</span>
                      </span>
                    </div>

                    <div className='checkbox-user-profile col-2'>
                      <label className='label-profile-radio inline'>
                        <span className={user?.name === email.domain ? 'active' : ''}></span>
                        <input
                          type="radio"
                          className="profile-input-radio on cursor-pointer"
                          name="email-address"
                          value={email.domain}
                          checked={user?.name === email.domain}
                          onChange={(e) => {
                            handleEmailChange(e, index)
                          }
                          }
                        />
                      </label>
                    </div>
                  </div>
                )
              })}
            </>
            }
          </div>

          <div className='profile-address-switch-container merge-start'>
            <div className='row-content-user-address-profile btn-profile-add'>
              <div className='show-btn profile-select add-account-btn' onClick={() => {
                history('signup')
              }} > {logo.add_plus_svg} Add New Account </div>
            </div>
          </div>

          <div className='logout-btn-profile-container cursor-pointer' onClick={() => {
            cookies.set("userObject", '', { path: "/", maxAge: 0 });
            deleteDatabase();
            window.open('/', '_self');
          }}>
            {logo.log_out_profile}  <span className='span-text-logout-btn'> Log Out </span>

          </div>
        </div>
      }

    </>





  )
}

export default Profile
