
import Web3 from 'web3';
import contractData from '../web3/hostContract.json';
import contractDataPool from '../web3/emailPool.json';

import config from '../web3/web3Config.json';
import { formatListValueFromArray, validateTheWebReturedValues } from './object-validation-helper';
import { transactionAction } from './chain-helper';

const contractAddress = config.CONTRACT;
const web3 = new Web3(window.ethereum);
const contractMethods = new web3.eth.Contract(contractData.contract, contractAddress);
const currentDateValue = new Date();

const formattedDateTime = currentDateValue.toLocaleDateString('en-US', {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric'
}) + ' ' + currentDateValue.toLocaleTimeString('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
});

// Function to get public key from smartcontract asynchronously
export const getPublicKey = async (emailObject, isSameHost, contactAddressFromName, jsonValue) => {


    try {
        let publicKey = null;
        if (isSameHost) {
            publicKey = await contractMethods.methods.getPublicKeyOfUser(emailObject.recipient).call();
        } else {
            const retrivedWeb3Value = new Web3(jsonValue.rpc_url);
            const retrivedContract = new retrivedWeb3Value.eth.Contract(contractData.contract, contactAddressFromName);

            if (contactAddressFromName) {
                publicKey = await retrivedContract.methods.getPublicKeyOfUser(emailObject.recipient).call();
            }
        }
        return publicKey;
    } catch (error) {
        console.log(error);
        return null;
    }

} 


export const sendEmailOnDifferentChain = async (emailJson, senderChainAddress, account , receivingChainJson) => {


  let jsonValue = null;
  
  if (senderChainAddress["0"] && senderChainAddress["1"]) {
    jsonValue = JSON.parse(senderChainAddress["1"]);
  }

  const jsonData = {
    "emails" : emailJson
  }

  const poolContractAddress = jsonValue.poolContract;

  const emailString = JSON.stringify(jsonData); 
  const functionParams = [receivingChainJson.user_domain , emailString];

  const contractMethodsEmailPool = new web3.eth.Contract(contractDataPool.contract, poolContractAddress);  
  const txHash = await transactionAction(contractMethodsEmailPool , "saveEmailsBasedOnDomain", functionParams , account); 
  return txHash;
};

  

export const formatDate = (dateString) => {
    // Convert the dateString to a Date object
    const date = new Date(dateString);
    // Get the day of the week
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = days[date.getDay()];
    // Get the month
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[date.getMonth()];
    // Get the day of the month
    const day = date.getDate();
    // Get the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // Get the current date and time
    const currentDate = new Date();
    // Calculate the difference in milliseconds
    const difference = currentDate - date;
    // Convert milliseconds to days, hours, and minutes
    const daysAgo = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hoursAgo = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesAgo = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    
    // Check if the date is today
    if (daysAgo === 0) {
      return `${hours}:${minutes < 10 ? '0' + minutes : minutes} (${hoursAgo === 0 ? minutesAgo + ' minutes ago' : hoursAgo + ' hours ago'})`;
    } else {
      return `${dayOfWeek}, ${day} ${month}, ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes} (${daysAgo === 1 ? '1 day ago' : daysAgo + ' days ago'})`;
    }
}


export const copyEmail = async (email , destinationFolderData , userName) => {
    
    const emailsDetails = destinationFolderData.emails;
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });

    const jsonString = JSON.stringify(email, (key, value) =>
        typeof value === 'bigint'
            ? value.toString() + 'n'
            : value
    );

    const valueJson = [... emailsDetails ,  jsonString ];

    const functionParams = [userName , parseInt(destinationFolderData.id) , destinationFolderData.name , destinationFolderData.date , destinationFolderData.filterType , destinationFolderData.filterValue , valueJson  ];
    await transactionAction(contractMethods , "updateFolder", functionParams , accounts[0]); 

}

export const deleteFolderMessage = async (email , destinationFolderData , userName) => {
  const functionParams = [ userName , parseInt(destinationFolderData.id) , destinationFolderData.name , destinationFolderData.date , destinationFolderData.filterType , destinationFolderData.filterValue , email ];
  const accounts = await window.ethereum.request({ method: 'eth_accounts' });
  await transactionAction(contractMethods , "updateFolder", functionParams , accounts[0]);
}