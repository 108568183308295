import React, { useState, useEffect } from 'react';
import CommonMessage from "../components/CommonMessage";
import { web3AccountCheck } from '../helper/web3helper';
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import { formatMessageList } from '../helper/dataFormatHelper';
import Cookies from "universal-cookie";
import { useSelector } from 'react-redux';
const cookies = new Cookies();

const Sent = () => {
  const [user] = useState(cookies.get("userObject"));
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [web3Contract, setWeb3Contract] = useState('');
  const [allBoxList, setAllBoxList] = useState([]);
  const [readList, setReadList] = useState([]);
  const [unreadList, setUnreadList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rendered, setRendered] = useState(false);
  const [renderTime, setRenderTime] = useState(new Date());
  const userName = user.name;

  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log('MetaMask is not installed');
    }

    if (web3Contract) {
      setEmail();
    }

  }, []);

  useEffect(() => {
    if (account && web3Value) {
      const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
      setWeb3Contract(contractInstance);
    }
  }, [web3Value, account]);

  const getRefreshMessage = useSelector((state) => state.refreshMessage);
  useEffect(() => {
    if (web3Contract || getRefreshMessage) { setEmail(); }
  }, [web3Contract, getRefreshMessage]);


  const reRender = () => { setEmail(); }


  async function setEmail(loaderRequired = true) {
    try {

      if (!web3Contract) return;

      if (loaderRequired) {
        setLoader(true);
        setRendered(false);
      }

      const emailList = await web3Contract.methods.getSentEmailList(userName).call({ from: account });

      const formattedResult = emailList
        .filter(email => email.sender)
        .map(email => ({
          id: parseInt(email.id),
          subject: email.subject,
          encryptedData: email.encryptedData,
          created_at: email.receivedDate,
          isStarred: false,
          sender: email.sender,
          isRead: true,
          header: email.receiver,
          ...email
        }))

      const { allList, readList, unReadList } = await formatMessageList(formattedResult.reverse(), "Sent");
      setAllBoxList(allList);
      setReadList(readList);
      setUnreadList(unReadList);

      if (loaderRequired) {
        setRendered(true);
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (rendered) {
      setLoader(false)
    }
  }, [rendered]);


  return (
    <div className="inbox-container">
      <CommonMessage messageList={allBoxList} pageType={"Sent"} unreadList={unreadList} readList={readList} loader={loader} reRender={reRender} />
    </div>
  );
}


export default Sent
