import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { getSingnature, decodeHtml } from "../helper/settingsHelper.js";
import { getGeneralSettings } from '../helper/settingsHelper.js';
const editorConstant = {
  toolBar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'size': [] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
  ],
  format: [
    'header', 'font', 'size',
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent'
  ]
}
const Editor = ({ placeholder, check, initialMessage, attachmentFiles, handleRemoveFile, formattedDate, initialReceiver, decryptedMessage }) => {
  const [editorHtml, setEditorHtml] = useState(localStorage.getItem("replyEmail") || "");
  const [isTyped, setIsTyped] = useState(false);
  const [editorMessage, setEditorMessage] = useState('');
  const [isSpellCheck, setIsSpellCheck] = useState('false');
  const [theme] = useState('snow');
  const html = check ? `<div>
      <p><br></p> 
      <p><br>---------------------------------------------------------------------</p>
      <p>On ${formattedDate}, ${initialReceiver} wrote:</p> 
      <blockquote>${decryptedMessage}</blockquote>
      <p></p>
    </div>` : ``;

  useEffect(() => {
    if (!check) {
      localStorage.setItem("replyEmail", "");
      setIsTyped(true);
      setEditorHtml('');
      setEditorMessage('');
      singnature('');
    } else {
      setEditorMessage(html);
      singnature(html);
    }
  }, [check, html]);


  const singnature = async(html) => {
    const signatureData = await getSingnature();
    if(signatureData.signature){
      const decodedSignature = signatureData.isHtml ? decodeHtml(signatureData.signature) : signatureData.signature;
      setEditorMessage(html+`<div>
        <p><br></p> 
        <p><br></p>
        ${decodedSignature}
      </div>`);
    }
  }
  useEffect(() => {
    const getData = async () => {
      const generalSettings = await getGeneralSettings();
      setIsSpellCheck(generalSettings.isSpellChecked ? "true" : "false");
    }
    getData();
  }, []);

 
  useEffect(() => {
    const editor = document.querySelector('.ql-editor');
    if (editor) {
      editor.focus();
      editor.setAttribute('spellcheck', isSpellCheck);
    }
  }, [editorHtml]);

  return (
    <div className="quil-text-editor reply">
      <ReactQuill
        theme={theme}
        onChange={(e) => {
          setTimeout(() => {
            setIsTyped(true);
          }, 1000);

          localStorage.setItem("replyEmail", e);
          setEditorHtml(e);
          setEditorMessage(e);
        }}
        value={editorMessage}
        modules={{ toolbar: editorConstant.toolBar}}
        placeholder={placeholder}
      />
    </div>
  );
};

Editor.propTypes = {
  placeholder: PropTypes.string,
  check: PropTypes.bool.isRequired,
  initialMessage: PropTypes.string,
  handleRemoveFile: PropTypes.func.isRequired,
  formattedDate: PropTypes.string.isRequired,
  initialReceiver: PropTypes.string.isRequired,
  decryptedMessage: PropTypes.string
};

export default Editor;
