import db from './db-service.js';

// Common transaction method for all the write operation
export const getMessageByIdAndType = async (id, type) => {
    const value = await db[`${type}`].where("mailId").equals(id).toArray();
    return value;
}

export const getDecryptedContentFromIndexedDb = async (id, type) => {
    try {
        const value = await getMessageByIdAndType(id, type);
        return JSON.parse(value[0].decryptedMail);
    } catch (error) {
        return null
    }
}

export async function syncIndexedDB(id , msg, type){

    try {
      if(type){
          const pageTypeValue = type.toLowerCase();
          // const idformat = parseInt(id) ? parseInt(id) : id;
          const idformat = type === 'Insecure' ? id : parseInt(id);
          const decryptedObject = {  mailId : idformat, decryptedMail : msg, type : pageTypeValue };            
          const value = await getMessageByIdAndType(decryptedObject.mailId , pageTypeValue);
              
          if (value.length) {
            await db[`${pageTypeValue}`].update(value[0].id, decryptedObject);
          } else {
            // create;
            await db.table(`${pageTypeValue}`).add(decryptedObject);
          }
      }
      
    } catch (error) {
      console.log(error);
    }
  }