import React, { useEffect, useState, useRef } from "react";
import { Modal, Popover, TimePicker } from 'antd';
import "./modal.css";
import { Calendar as CalendarIcon, MapPinCheck } from "lucide-react";
import Select from "react-select";
import { calender_svg } from "../assets/svg/calender_svg.js";
import { getSuggestion } from '../helper/contract-helper.js';
import { fetchLocation } from '../helper/commonHelper.js';
import { v4 as uuidv4 } from 'uuid';
import { transactionAction } from '../helper/chain-helper.js';
import { sendInvite } from '../helper/emailHelper.js';
import { toast, Bounce } from 'react-toastify';
import { getCalendarContract } from '../helper/contract-helper.js';
import { CompleteLoader } from './common-alert.js';
import { DayPicker } from 'react-day-picker';
import "react-day-picker/style.css";
import moment from 'moment';
import 'antd/dist/reset.css';
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import { Space } from "antd";
import SlotCalendar from "../components/SlotCalendar.js";
import RepeatEvent from "../components/createRepeatEvent.js";
import { convertToTimeZone } from "../helper/eventHelper.js";
const cookies = new Cookies();




export const CreateEvent = (params) => {
    const [guestText, setGuestText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [emailSuggestions, setEmailSuggestions] = useState([]);
    const [guestList, setGuestList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [inputLocationValue, setInputLocationValue] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [locations, setLocations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const [popoverVisibleFrom, setPopoverVisibleFrom] = useState(false);
    const [popoverVisibleTo, setPopoverVisibleTo] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).startOf('day'));
    const [toDate, setToDate] = useState(moment(new Date()).startOf('day'));
    const user = cookies.get("userObject");
    const [fromTime, setFromTime] = useState(dayjs('12:00 AM', 'hh:mm A'));
    const [toTime, setToTime] = useState(dayjs('12:00 AM', 'hh:mm A'));
    const [isCheck, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [buttonvisible, setButtonvisible] = useState(true);
    const timePickerRef = useRef(null);
    const timePicker = useRef(null);
    const [repeat, setRepeat] = useState('');
    const [customRepeat, setCustomRepeat] = useState('');

    useEffect(() => {
        const getSug = async () => {
            const filteredAddressBook = await getSuggestion();
            setEmailSuggestions(filteredAddressBook);
        }
        setEmpty();
        getSug();
    }, [params])

    const setEmpty = () => {
        setSuggestions([]);
        setEmailSuggestions([]);
        setGuestList([]);
        setGuestText('');
        setDescription('');
        setTitle('');
        setVisible(false);
        setToTime(dayjs('12:00 AM', 'hh:mm A'));
        setFromTime(dayjs('12:00 AM', 'hh:mm A'));
        setSelectedDate(moment(new Date()).startOf('day'));
        setToDate(moment(new Date()).startOf('day'));
    }

    const handleGuest = (data) => {
        if (!guestList.includes(data)) {
            setGuestList([...guestList, data]);
        }
        setGuestText('');
        setSuggestions([]);
    }

    const createEvent = async () => {
        try {
            if (!title.trim()) {
                toast.error('Title is required', {
                    position: "top-center",
                    transition: Bounce,
                })
                return;
            }
            setLoader(true);
            const UniqueId = uuidv4();
            const list = [];
            if (selectedLocation && selectedLocation.label && selectedLocation.label.trim()) {
                list.push({
                    key: 'location',
                    value: selectedLocation.label.trim()
                })
            }
            if (guestList.length > 0) {
                guestList.map((email) => {
                    list.push({
                        key: 'guest',
                        value: email
                    })
                })
            }
            if (repeat) {
                list.push(
                    {
                        key: 'repeatEvent',
                        value: repeat
                    }
                )
            }
            if (customRepeat) {
                list.push(
                    {
                        key: 'customRepeatEvent',
                        value: customRepeat
                    }
                )
            }
            let selectedTimezone = localStorage.getItem('timeZone');
            const { formattedFrom, formattedTo } = convertToTimeZone(selectedDate, toDate, fromTime, toTime, selectedTimezone);

            const eventParams = {
                uid: UniqueId.toString(),
                title,
                description: description || "",
                fromTime: formattedFrom || "",
                toTime: formattedTo || "",
                done: false,
                list
            };
            const inputContact = {
                contact: eventParams
                    .list
                    .filter((event) => event.key === "guest")
                    .map((event) => ({ key: "contact", value: event.value }))
            };
            const solparams = [eventParams, user.name, inputContact];
            const contractInstance = getCalendarContract();
            const hash = await transactionAction(contractInstance, "addEvent", solparams, user.wallet);
            if (hash) {
                if (guestList.length > 0) {
                    const sentEmails = await sendInvite(guestList, eventParams, user.name, user.wallet, "You are invited to the meeting.", "Please find the details in the attachment.", []);
                    if (!sentEmails) {
                        toast.error('Faild to send the invite.', {
                            position: "top-center",
                            transition: Bounce,
                        });
                        setLoader(false);
                        return;
                    }
                }
            } else {
                toast.error('Faild to create the event.', {
                    position: "top-center",
                    transition: Bounce,
                });
                setLoader(false);
                return;
            }
            setLoader(false);
            toast.success('Successfully created the event', {
                position: "top-center",
                transition: Bounce,
            });
            params.close();
        } catch (error) {
            console.error(error);
            toast.error('Faild to create the event.', {
                position: "top-center",
                transition: Bounce,
            });
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }

    const handleInputChange = (inputValue) => {
        setInputLocationValue(inputValue);
        getAsyncLocations(inputValue);
    };

    const getAsyncLocations = async (inputValue) => {
        const locationValue = await fetchLocation(inputValue);
        setLocations(locationValue);
    }

    const handleLocationSelect = (selectedOption) => {
        if (selectedOption) {
            setSelectedLocation(selectedOption);
            setInputLocationValue(selectedOption.label);
        } else {
            setSelectedLocation(null);
            setInputLocationValue("");
        }
        setLocations([]);
    };

    const handledelete = (index, e) => {
        e.preventDefault();
        const updatedBulkEmail = guestList.filter((_, i) => i !== index);
        setGuestList(updatedBulkEmail);
    }

    const handleGuestInputChange = (value) => {
        const lastEmailPart = value.trim();
        setGuestText(lastEmailPart);
        const regexp =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (lastEmailPart) {
            const filterEmails = emailSuggestions.filter((email) =>
                email.toLowerCase().includes(lastEmailPart.toLowerCase()) &&
                email.toLowerCase() !== lastEmailPart.toLowerCase()
            )
            setSuggestions(filterEmails);
            const isValidEmail = regexp.test(String(lastEmailPart).toLowerCase());
            if (filterEmails.length === 0 && isValidEmail) {
                setSuggestions([lastEmailPart])
            }
        } else {
            setSuggestions([]);
        }
    }

    const handlePopoverOpen = (field) => {
        setActiveField(field);
    };

    const handleDateChange = (date) => {
        const momentDate = moment(date);
        if (activeField === "from") {
            setSelectedDate(momentDate);
            if (toDate.isBefore(momentDate, "day")) {
                setToDate(momentDate);
            }
        } else if (activeField === "to") {
            setToDate(momentDate);
        }
    };

    const disabledDates = (date) => {
        const momentDate = moment(date);
        if (activeField === "to") {
            return momentDate.isBefore(selectedDate, "day");
        }
        return false;
    };

    const handletimevisible = () => {
        setVisible(true);

    };

    const handleCheckboxChange = () => {
        if (isCheck) {
            setChecked(false);
            setButtonvisible(true)
        } else {
            setFromTime("12:00 AM")
            setToTime("12:00 AM")
            setChecked(true);
            setButtonvisible(false)
        }
    };

    const handleFromTimeChange = (time, timeString) => {
        setFromTime(timeString);
    };

    const handleToTimeChange = (time, timeString) => {
        setToTime(timeString);
    };
    useEffect(() => {
        if (!fromTime || !toTime) return;
        const timeIn24Hour = moment(fromTime, 'hh:mm A').format('HH:mm');
        const timeto24Hour = moment(toTime, 'hh:mm A').format('HH:mm');
        const formattedDate = moment(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD");
        const formattedtoDate = moment(toDate, "MM/DD/YYYY").format("YYYY-MM-DD");
        if (formattedDate === formattedtoDate) {
            if (timeIn24Hour > timeto24Hour) {
                const adjustedTime = dayjs(timeIn24Hour, 'HH:mm').add(1, 'hours').format('hh:mm A');
                setToTime(adjustedTime);
            }
        }
    }, [fromTime, toTime, selectedDate, toDate]);

    const disabledTime = (current) => {
        const fromTimeObj = dayjs(fromTime, "hh:mm A");
        const fromHour = fromTimeObj.hour();
        const fromMinute = fromTimeObj.minute();
        const formattedDate = moment(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD");
        const formattedtoDate = moment(toDate, "MM/DD/YYYY").format("YYYY-MM-DD");

        if (formattedDate === formattedtoDate) {
            return {
                disabledHours: () => {
                    const disabledHours = [];
                    for (let i = 0; i < fromHour; i++) {
                        disabledHours.push(i);
                    }
                    return disabledHours;
                },
                disabledMinutes: (selectedHour) => {
                    if (selectedHour === fromHour) {
                        const disabledMinutes = [];
                        for (let i = 0; i < fromMinute; i++) {
                            disabledMinutes.push(i);
                        }
                        return disabledMinutes;
                    }
                    return [];
                }
            };
        }

        return {};
    };

    const handleRepeatStateChange = (newState) => {
        setRepeat(newState);
    };

    const handleCustomRepeatStateChange = (newState) => {
        setCustomRepeat(newState);
    };

    return (
        <>
            <Modal
                className="parent-div-content-event-preview"
                open={params.isOpen}
                closeIcon={null}
                footer={null}
            >
                <div className="event-container " style={{ height: "60vh", overflow: "auto", scrollbarWidth: "thin" }}>
                    <div className="event-preview-header">
                        <span><span className="event-create-text">Create</span> <span className="event-cevent-text text-gradient">Event</span></span>
                        <div onClick={params.close} className="cursor-pointer">
                            {calender_svg.close_svg}
                        </div>
                    </div>

                    <div className="event-rendering-section">
                        <div>
                            <input className="event-input" placeholder="Add Title" value={title} onChange={(e) => {
                                setTitle(e.target.value)
                            }} />
                        </div>
                    </div>
                    <div className="flex mt-3">
                        <div style={{ width: "60%" }}>
                            <div className="event-date-picker-section">
                                <CalendarIcon />
                                <Popover
                                    content={
                                        <div style={{ display: "flex" }}>
                                            <DayPicker
                                                mode="single"
                                                selected={selectedDate.toDate()}
                                                onSelect={(date) => handleDateChange(date, "from")}
                                                disabled={disabledDates}
                                            />
                                        </div>
                                    }
                                    trigger="click"
                                    visible={popoverVisibleFrom}
                                    onVisibleChange={(visible) => setPopoverVisibleFrom(visible)}
                                    placement="bottomLeft"
                                >
                                    <div className="d-flex gap-3">
                                        {/* From Field */}
                                        <span
                                            onClick={() => handlePopoverOpen("from")}
                                            className="cursor-pointer flex align-items-center gap-2"
                                        >
                                            <span
                                                style={{
                                                    color: "rgba(12, 12, 12, 0.95)",
                                                    paddingRight: "2px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                From:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "normal",
                                                        color: "graytext",
                                                    }}
                                                >
                                                    {selectedDate.format("MMMM Do, YYYY")}
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </Popover>
                                {visible && selectedDate && (
                                    <>
                                        {!isCheck && (
                                            <div className="flex justify-center">
                                                <div className="mr-2" style={{ marginRight: "5px" }}>
                                                    <Space wrap>
                                                        <TimePicker
                                                            ref={timePicker}
                                                            needConfirm={false}
                                                            showOK={false}
                                                            format="hh:mm A"
                                                            use12Hours
                                                            onChange={handleFromTimeChange}
                                                            allowClear={false}
                                                            value={dayjs(fromTime, "hh:mm A")}
                                                            getPopupContainer={triggerNode => triggerNode.parentNode}
                                                            size="large"
                                                        />
                                                    </Space>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                <Popover
                                    content={
                                        <div style={{ display: "flex" }}>
                                            <DayPicker
                                                mode="single"
                                                selected={toDate.toDate()}
                                                onSelect={(date) => handleDateChange(date, "to")}
                                                disabled={disabledDates}
                                            />
                                        </div>
                                    }
                                    trigger="click"
                                    visible={popoverVisibleTo}
                                    onVisibleChange={(visible) => setPopoverVisibleTo(visible)}
                                    placement="bottomRight"
                                >
                                    <div className="d-flex gap-3">
                                        {/* To Field */}
                                        <span
                                            onClick={() => handlePopoverOpen("to")}
                                            className="cursor-pointer flex align-items-center gap-2"
                                        >
                                            <span
                                                style={{
                                                    color: "rgba(12, 12, 12, 0.95)",
                                                    paddingRight: "2px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                To:{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "normal",
                                                        color: "graytext",
                                                    }}
                                                >
                                                    {toDate.format("MMMM Do, YYYY")}
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </Popover>
                                {visible && selectedDate ? (
                                    <>
                                        {!isCheck && (
                                            <div className="flex justify-center">

                                                <div>
                                                    <Space wrap>
                                                        <TimePicker
                                                            ref={timePickerRef}
                                                            popupClassName="time-picker-dropdown"
                                                            needConfirm={false}
                                                            showOK={false}
                                                            format="hh:mm A"
                                                            use12Hours
                                                            onChange={handleToTimeChange}
                                                            value={dayjs(toTime, "hh:mm A")}
                                                            disabledTime={disabledTime}
                                                            allowClear={false}
                                                            getPopupContainer={triggerNode => triggerNode.parentNode}
                                                            size="large"
                                                        />
                                                    </Space>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    selectedDate && (
                                        <div>
                                            <button
                                                onClick={handletimevisible}
                                                className="buttoncss"
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                            {visible && (
                                <div className="mt-3 mb-3" style={{ marginLeft: "25px" }}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isCheck}
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="ml-[5px] pb-[5px]" style={{ marginLeft: "5px" }}>All Day</span>
                                    </label>
                                </div>
                            )}
                            <RepeatEvent onRepeatStateChange={handleRepeatStateChange} onCustomRepeatStateChange={handleCustomRepeatStateChange} fromDate={selectedDate} />
                            <div className="event-add-guest-section">
                                <div className="guest-input-section">
                                    <img src="/guest.png" className="mr-1" alt="addguest" width={18} height={18} />
                                    <input placeholder="Add guest" value={guestText} onChange={(e) => handleGuestInputChange(e.target.value)} />
                                </div>
                                {suggestions && suggestions.length > 0 && (
                                    <div className="guest-drop overflow-auto">
                                        {suggestions.length > 0 &&
                                            suggestions.map((contact, index) => (
                                                <div
                                                    className="flex align-items-center gap-2 cursor-pointer"
                                                    key={index}
                                                    onClick={() => {
                                                        handleGuest(contact);
                                                    }}
                                                >
                                                    <button className="guestlogo">
                                                        {contact[0].toUpperCase()}
                                                    </button>

                                                    <span>{contact}</span>
                                                </div>
                                            ))}
                                    </div>
                                )}
                                <div className="flex flex-column mt-2 ps-4 gap-2 guest-list-section max-h-15rem overflow-auto">
                                    {guestList.map((value, index) => (
                                        <div key={index} className="flex align-items-center justify-content-between gap-2">
                                            <span>{value}</span>
                                            <button
                                                className="border-0 bg-transparent d-flex"
                                                onClick={(e) => handledelete(index, e)}
                                            >
                                                {calender_svg.close_svg}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="event-add-location-section mt-3 mb-3">
                                <MapPinCheck />
                                <Select
                                    value={selectedLocation}
                                    onInputChange={handleInputChange}
                                    onChange={handleLocationSelect}
                                    options={locations}
                                    className="location-select"
                                    placeholder="Pick a location"
                                    isClearable
                                    components={{
                                        DropdownIndicator: () => null
                                    }}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            border: "none",
                                            boxShadow: "none",
                                            "&:hover": {
                                                border: "none"
                                            },
                                            width: "200px"
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            border: "none"
                                        }),
                                        option: (base) => ({
                                            ...base,
                                            overflow: "auto"
                                        })
                                    }} />
                            </div>
                            <div className="event-description-section">
                                <textarea className="event-textarea" value={description} onChange={(e) => {
                                    setDescription(e.target.value);
                                }} placeholder="Add description" />
                            </div>
                        </div>
                        <div className='slot-calendar' style={{ width: "40%" }}>
                            <SlotCalendar fromDate={selectedDate} toDate={toDate} setFromDate={setSelectedDate} setToDate={setToDate} setFromTime={setFromTime} setVisible={setVisible} setToTime={setToTime} setChecked={setChecked} fromTime={fromTime} toTime={toTime} guestList={guestList} />
                        </div>
                    </div>
                </div>

                <div className="event-create-button-section pt-2">
                    <button className="event-create-button" onClick={() => {
                        createEvent();
                    }}>Create Event</button>
                </div>

            </Modal >
            {
                loader &&
                <CompleteLoader isOpen={loader} />
            }
        </>
    );
}