import Web3 from 'web3';
import hostABI from '../web3/hostContract.json';
import helperContract from '../web3/helperContract.json';
import contactContract from '../web3/contactContract.json';
import calendarContract from '../web3/calendarContract.json';
import config from '../web3/web3Config.json';
import Cookies from "universal-cookie";

const cookies = new Cookies();

const web3 = new Web3(window.ethereum);

export const getHostContract = () => {
    const hostContractMethod = new web3.eth.Contract(hostABI.contract, config.CONTRACT);
    return hostContractMethod;
}

export const getHostHelperContract = () => {
    const helperContractMethod = new web3.eth.Contract(helperContract.contract, config.HELPER);
    return helperContractMethod;
}

export const getContactContract = () => {
    const contactContractMethod = new web3.eth.Contract(contactContract.contract, config.CONTACT_CONTRACT);
    return contactContractMethod;
}

export const getCalendarContract = () => {
    const calendarContractMethod = new web3.eth.Contract(calendarContract.contract, config.CALENDAR_CONTRACT);
    return calendarContractMethod;
}

export const getUserDetails = () => {
    try {
        const user = cookies.get("userObject");
        return user;
    } catch (error) {
        return null;
    }
}

export const getSuggestion = async () => {
    const hostContract = getHostContract();
    const helperContract = getHostHelperContract();
    const contactContract = getContactContract();
    const calendarContract = getCalendarContract();
    const user = getUserDetails();
    const addressBookForUser = await hostContract.methods.getAddressBookForUser(user.name).call({ from: user.wallet });
    const web2AddressBookForUser = await helperContract.methods.getWeb2Emails(user.name).call({ from: user.wallet });
    const getCalendarContacts = await calendarContract.methods.getContacts(user.name).call({ from: user.wallet });
    const filteredCalendarContacts = getCalendarContacts
        .filter(item => item.key === "contact")
        .map(item => item.value);
    const getContacts = await contactContract.methods.getAllContacts(user.name).call({ from: user.wallet });
    const formattedContactsList = getContacts.filter((contact) =>
        !contact.attributes.some((attr) => attr.key === 'isDeleted' && attr.value === 'true')
    ).flatMap(contact => contact.contact.email);
    const filteredAddressBook = addressBookForUser.concat(web2AddressBookForUser, formattedContactsList, filteredCalendarContacts)
        .filter((email) => email !== "")
        .reduce((uniqueEmails, email) => {
            if (!uniqueEmails.includes(email)) {
                uniqueEmails.push(email);
            }
            return uniqueEmails;
        }, []).sort((a, b) => a.localeCompare(b));
    return filteredAddressBook;
}