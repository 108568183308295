import React from 'react'
import { logo } from '../assets/svg/svg'

const ViewMessageHeader = (props) => {

    const { messageList, index } = props;

    const totalMessages = messageList.length;
    const numberLength = totalMessages.toString().length;
    const currentPage = index + 1;

    // Convert currentnumber to a string and pad with leading zeros to match the length of total
    let paddedNumber = currentPage.toString().padStart(numberLength.toString().length, '0');


    return (
        <div className='box-common-header'>
            <div className='box-common-header-back cursor-pointer' disabled={true} onClick={() => {
                {
                    props.setIndex(null);
                    props.setIsDecrypted(null);
                    props.setDecryptedContent(null);
                    props.reRender();
                }
            }}>
                {logo.msg_box_back_btn}
            </div>

            {
                !props.isDecrypted && <div className='box-common-header-actions'>
                    <div className='box-common-header-actions-btn' onClick={() => {

                        if (currentPage === 1) return true;
                        const index = currentPage - 2;
                        props.setIndex(index);
                        const message = messageList[index];
                        props.openMessage(message);

                    }}>  {logo.back_arrow_svg} </div>
                    <div className='page-index-division'> <span className='current-page'> {paddedNumber} </span>  / {totalMessages} </div>
                    <div className='box-common-header-actions-btn' onClick={() => {

                        if (currentPage === totalMessages) return true;

                        props.setIndex(currentPage);
                        const message = messageList[currentPage];
                        props.openMessage(message);

                    }}> {logo.next_arrow_svg} </div>
                </div>
            }

        </div>
    )
}

export default ViewMessageHeader
