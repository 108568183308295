import React, { useState, useEffect, useRef } from "react";
import { logo } from '../../assets/svg/svg'
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { saveSenderEncryptedEmail, sendEmails } from "../../helper/emailHelper";
import Cookies from "universal-cookie";
import { getHostContract } from "../../helper/contract-helper";
import { CompleteLoader } from "../../modals/common-alert";
import { getCurrentDate } from "../../helper/object-validation-helper";
import { transactionAction } from "../../helper/chain-helper";
import { useDispatch, useSelector } from "react-redux";
import config from "../../web3/web3Config.json";
import db from '../../db/db-service';
import { sendAttachment } from '../../service/api-actions';
import { Popconfirm } from 'antd';
import { toast, Bounce } from 'react-toastify';
import Swal from 'sweetalert2'
import { getGeneralSettings } from '../../helper/settingsHelper.js';
import { getSuggestion } from '../../helper/contract-helper.js';
import { Calendar as CalendarIcon } from "lucide-react";
import { Tooltip } from "antd";
import "../../../src/assets/css/calendar.css"
const cookies = new Cookies();

const editorConstant = {
  toolBar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'size': [] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
  ],
  format: [
    'header', 'font', 'size',
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent'
  ]
}

const ComposeEmail = (props) => {
  const [user] = useState(cookies.get("userObject"));
  const [accountSettings, setAccountSettings] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [isCC, setIsCC] = useState(false);
  const [isBCC, setIsBCC] = useState(false);
  const [receiverName, setReceiverName] = useState(localStorage.getItem("recipient") || '');
  const [CC, setCC] = useState(localStorage.getItem("cc") || '');
  const [BCC, setBCC] = useState(localStorage.getItem("bcc") || '');
  const [subject, setSubject] = useState(localStorage.getItem("topic") || '');
  const [isSpellCheck, setIsSpellCheck] = useState('false');

  const dispatch = useDispatch();
  const draftData = useSelector((state) => state.draftData);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isMinimize = useSelector((state) => state.composeMin);
  const [suggestions, setSuggestions] = useState([]);
  const [inputType, setInputType] = useState('');
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState({
    receiver: false,
    cc: false,
    bcc: false
  });

  useEffect(() => {
    setReceiverName(localStorage.getItem("recipient") || '');
    setSubject(localStorage.getItem("topic") || '')
    setCC(localStorage.getItem("cc") || '');
    setBCC(localStorage.getItem("bcc") || '');
    if (CC) {
      setIsCC(true)
    }
    if (BCC) {
      setIsBCC(true)
    }
    localStorage.getItem("sendingEmail")

  }, [props]);

  async function setSettingsJson() {
    const filteredAddressBook = await getSuggestion();
    setEmailSuggestions(filteredAddressBook);
    try {
      const generalSettings = await getGeneralSettings();
      setAccountSettings(generalSettings);
    } catch (error) { }
  }

  async function composeEmail() {

    const isSavedOn = accountSettings.isSaveSent;
    const recipient = document.getElementById("receiver").value;
    const subject = document.getElementById("subject").value;
    const cc = isCC ? CC : "";
    const bcc = isBCC ? BCC : "";


    let isReceipientAdded = false;
    if (recipient || cc || bcc) isReceipientAdded = true;

    if (!isReceipientAdded) {
      toast.error("Please specify at least one recipient.", {
        position: "top-center",
        transition: Bounce,
      });

      setSendLoader(false);
      return;
    }

    if (!subject || !localStorage.getItem("sendingEmail")) {
      Swal.fire({
        text: "Send this message without a subject or text in the body?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1677ff",
        cancelButtonColor: "#ffffff",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: "compose-sweet-alert"
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch({ type: "DRAFT", payload: null });

          let defaultEncryptedMessage = "MSG";
          if (isSavedOn) {
            const emailObject = { recipient: recipient, subject: subject, message: localStorage.getItem("sendingEmail") };
            if (selectedFiles && selectedFiles.length) {
              const attachmentResult = await sendAttachment(selectedFiles, user.publicKey);
              emailObject.attachment = attachmentResult.data.returnValue;
            }
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            defaultEncryptedMessage = await saveSenderEncryptedEmail(emailObject, accounts);
          }

          const result = await sendEmails(recipient.replace(/\s/g, '').split(","), cc.replace(/\s/g, '').split(","), bcc.replace(/\s/g, '').split(","), subject, localStorage.getItem("sendingEmail"), isSavedOn, defaultEncryptedMessage, selectedFiles);
          setSendLoader(false);
          if (result) {

            localStorage.setItem("sendingEmail", "");
            dispatch({ type: "IS_COMPOSE", payload: false })
            props.setIsCompose(false);
            if (props.reRender) props.reRender();
          }
        } else {
          setSendLoader(false);
          return true;
        }
      });
    } else {
      dispatch({ type: "DRAFT", payload: null });

      let defaultEncryptedMessage = "MSG";
      if (isSavedOn) {
        const emailObject = { recipient: recipient, subject: subject, message: localStorage.getItem("sendingEmail") };
        if (selectedFiles && selectedFiles.length) {
          const attachmentResult = await sendAttachment(selectedFiles, user.publicKey);
          emailObject.attachment = attachmentResult.data.returnValue;
        }
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        defaultEncryptedMessage = await saveSenderEncryptedEmail(emailObject, accounts);
      }

      const result = await sendEmails(recipient.replace(/\s/g, '').split(","), cc.replace(/\s/g, '').split(","), bcc.replace(/\s/g, '').split(","), subject, localStorage.getItem("sendingEmail"), isSavedOn, defaultEncryptedMessage, selectedFiles);
      if (result) {
        const notifyMessage = `Message Sent`;
        toast.info(notifyMessage, {
          position: "top-center",
          transition: Bounce,
        })
      }
      setSendLoader(false);
      if (result) {
        localStorage.setItem("sendingEmail", "");
        dispatch({ type: "IS_COMPOSE", payload: false })
        props.setIsCompose(false);
        if (props.reRender) props.reRender();
      }
    }
  }



  async function saveDraftFunction() {
    const draftJSON = JSON.parse(draftData);
    const draft = {
      to: receiverName,
      bcc: isBCC ? BCC : "",
      cc: isCC ? CC : "",
      subject: subject || "(No Subject)",
      message: localStorage.getItem("sendingEmail"),
      sender: user.name,
    }
    const decryptedObject = { json: draft };
    if (draftJSON && draftJSON.id) {
      const value = await db.table('draft').update(draftJSON.id, decryptedObject);
      return value;
    } else {
      const value = await db.table('draft').add(decryptedObject);
      return value;
    }

    // const recipient = document.getElementById("receiver").value;
    // const subject = document.getElementById("subject").value;
    // const cc = isCC ? CC : "";
    // const bcc = isBCC ? BCC : "";
    // const senderData = user.name;

    // const objectData = {
    //   created_at: getCurrentDate(),
    //   emailDetails: "",
    //   encryptedData: "encryptedData",
    //   senderName: senderData,
    //   subject: subject,
    //   isRead: false,
    //   isDeleted: false,
    //   recipient: recipient,
    //   cc: cc,
    //   bcc: bcc,
    //   isStarred: false
    // }

    // const functionParams = [senderData, JSON.stringify(objectData)];
    // const contractMethods = getHostContract();

    // await transactionAction(contractMethods, "saveDraft", functionParams, user.wallet);
    // dispatch({ type: "DRAFT", payload: null });

  }



  useEffect(() => {
    setSettingsJson();
  }, []);

  //outBox
  const outBoxIndex = useSelector((state) => state.outBoxIndex);
  useEffect(() => {
    const fetchReceiverData = async () => {
      if (outBoxIndex !== null) {
        const indexdb = await db.table('outbox').toArray();
        setReceiverName(indexdb[outBoxIndex].json.to);
        document.getElementById("subject").value = indexdb[outBoxIndex].json.subject;
        if (indexdb[outBoxIndex].json.cc.length && indexdb[outBoxIndex].json.cc.join(',')) {
          setIsCC(true);
          setCC(indexdb[outBoxIndex].json.cc.join(','));
        }
        if (indexdb[outBoxIndex].json.bcc.length && indexdb[outBoxIndex].json.bcc.join(',')) {
          setIsBCC(true);
          setBCC(indexdb[outBoxIndex].json.bcc.join(','));
        }
        localStorage.setItem("sendingEmail", indexdb[outBoxIndex].json.message);
        dispatch({ type: "OUTBOX", payload: null, value: false });
      }
    };
    fetchReceiverData();
  }, [outBoxIndex]);


  const Editor = () => {
    const [editorHtml] = useState(localStorage.getItem("sendingEmail") || "");
    const [theme] = useState('snow');
    const quillRef = useRef(null);


    const handleUndo = () => {
      const quill = quillRef.current.getEditor();
      quill.history.undo();
    };


    const handleRedo = () => {
      const quill = quillRef.current.getEditor();
      quill.history.redo();
    };

    const handleFileChange = async (event) => {
      const newFiles = Array.from(event.target.files);
      const combinedFiles = [...selectedFiles, ...newFiles];
      const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
      const totalSize = combinedFiles.reduce((acc, file) => acc + file.size, 0);
      if (totalSize > maxSize) {
        toast.error("Total file size exceeds the limit of 25 MB.", {
          position: "top-center",
          transition: Bounce,
        });
        return;
      }
      setSelectedFiles(combinedFiles);
    };

    useEffect(() => {
      const getData = async () => {
        const generalSettings = await getGeneralSettings();
        setIsSpellCheck(generalSettings.isSpellChecked ? "true" : "false");
      }
      getData();
    }, []);


    useEffect(() => {
      const editor = document.querySelector('.ql-editor');
      if (editor) {
        editor.setAttribute('spellcheck', isSpellCheck);
      }
    }, [editorHtml]);


    const handleRemoveFile = (indexToRemove) => {
      setSelectedFiles(prevFiles => prevFiles.filter((file, index) => index !== indexToRemove));
    }
    const handleTabKey = (e) => {
      const quillEditor = quillRef.current.getEditor();
      const editorContent = quillEditor.root;
      if (e.key === "Tab") {
        e.preventDefault();
        editorContent.focus();
      }
    };

    return (
      <div className="quil-text-editor">
        <ReactQuill theme={theme} onChange={async (e) => { localStorage.setItem("sendingEmail", e); }} ref={quillRef} value={editorHtml} modules={{ toolbar: editorConstant.toolBar }} onKeyDown={handleTabKey} />
        <div className="attachment-file-rendering-method">
          {selectedFiles.length > 0 && (
            <div className="attachment-section-element">
              {selectedFiles.map((file, index) => (
                <a key={index}>{file.name} <span className="delete-icon cursor-pointer" onClick={() => handleRemoveFile(index)}> &#10006;
                </span></a>
              ))}
            </div>
          )}
        </div>
        <div className="div-btn-action-compose-email">
          <div className="compose-emails-actions text-selection">
            <div className="compose-email-bth-operations">

              <div className="delete-functionality cursor-pointer" title="Discard draft" >   <span onClick={() => {
                localStorage.setItem("sendingEmail", "");
                dispatch({ type: "IS_COMPOSE", payload: false });
                dispatch({ type: "DRAFT", payload: null });
                props.setIsCompose(false);
              }}>{logo.compose_action_delete}</span> </div>
              <div className="mobile-undo-redu-attachment flex items-center gap-10">
                <div className="undo-redu-functionality" >
                  <div className="undo cursor-pointer" onClick={handleUndo}> {logo.undo_action_compose} </div>
                  <div className="redo cursor-pointer" onClick={handleRedo} > {logo.redo_action_compose} </div>
                </div>
                <div className="add-attachment-functionality">
                  <div className="btn-attachment cursor-pointer" onClick={() => document.getElementById('fileInput').click()}>
                    {logo.attachment_icon} <span className="add-file-component"> add file </span>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </div>
                <Tooltip title="Create event">
                  <div className="btn-event cursor-pointer mt-2" onClick={() => {
                    window.dispatchEvent(new Event("NEW_EVENT"));
                  }}>
                    <CalendarIcon />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="send-compose-email text-selection" onClick={async () => {
            setSendLoader(true);
            try {
              composeEmail();

            } catch (error) {
              setSendLoader(false);
              dispatch({ type: "IS_COMPOSE", payload: false })
              props.setIsCompose(false);
            }
          }}>
            <div className="btn-message-compose"> <div> Encrypt and Send </div> <div> (Gas 0.005 {config.SYMBOL}) </div>  </div>
            <div> {logo.compose_svg} </div>
          </div>

        </div>
        <div className="position-abo-messsage"> Message : </div>
      </div>
    );
  };

  const appendClassName = (isCC && isBCC) ? "both-carbon copy" : (isCC) ? "carbon" : (isBCC) ? "carbon" : "no-cc-bcc";

  const addToDraft = async () => {
    Swal.fire({
      text: "Are you sure you want to save draft?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSendLoader(true);
        await saveDraftFunction();
        localStorage.setItem("sendingEmail", "");
        dispatch({ type: "COMPOSE_MIN", payload: false });
        dispatch({ type: "DRAFT", payload: null });
        dispatch({ type: "IS_COMPOSE", payload: false })
        props.setIsCompose(false);
        props.reRender();
        setSendLoader(false);
      } else {
        await closeCompose();
      }
    });
  }

  const closeCompose = async () => {
    dispatch({ type: "COMPOSE_MIN", payload: false });
    dispatch({ type: "IS_COMPOSE", payload: false })
    dispatch({ type: "DRAFT", payload: null });
    props.setIsCompose(false);
  }

  if (isMinimize) {

    return (
      <div className="minimise-compose-div-element">
        <div className="compose-new-message-txt"> New Message </div>
        <div className="cursor-pointer" >
          <div onClick={() => { dispatch({ type: "COMPOSE_MIN", payload: false }); }} > {logo.compose_max}    </div>
          <div onClick={async () => { addToDraft(); }}  > {logo.compose_close_light}  </div>
        </div>
      </div>
    )
  }
  const isMini = isMinimize ? "minimize-compose" : "normal-compose";

  const handleInputChange = (value, setValue, fieldType) => {
    setValue(value);
    const lastEmailPart = value.split(',').pop().trim();
    if (lastEmailPart) {
      setSuggestions(emailSuggestions.filter((email) =>
        email.toLowerCase().includes(lastEmailPart.toLowerCase()) &&
        email.toLowerCase() !== lastEmailPart.toLowerCase()
      ));
      setInputType(fieldType);
    } else {
      setSuggestions([]);
    }
  };


  const handleSuggestionClick = (email) => {
    if (inputType === 'receiver') {
      const updatedReceivers = receiverName.split(',');
      updatedReceivers.pop();
      updatedReceivers.push(email);
      setReceiverName(updatedReceivers.join(',') + ',');
      localStorage.setItem("recipient", updatedReceivers.join(',') + ',');
    } else if (inputType === 'cc') {
      const updatedCC = CC.split(',');
      updatedCC.pop();
      updatedCC.push(email);
      setCC(updatedCC.join(',') + ',');
      localStorage.setItem("cc", updatedCC.join(',') + ',');
    } else if (inputType === 'bcc') {
      const updatedBCC = BCC.split(',');
      updatedBCC.pop();
      updatedBCC.push(email);
      setBCC(updatedBCC.join(',') + ',');
      localStorage.setItem("bcc", updatedBCC.join(',') + ',');
    }
    setSuggestions([]);

  };

  const handleFocus = (fieldType) => {
    setSuggestions([]);
    setIsFocused(prevState => ({
      receiver: false,
      cc: false,
      bcc: false,
    }));
    setIsFocused(prevState => ({ ...prevState, [fieldType]: true }));
  };

  const handleTabKey = (e) => {
    const editorContent = document.querySelector('.ql-editor');
    if (e.key === "Tab") {
      e.preventDefault();
      editorContent.focus();
    }
  };

  return (

    <div className={'message-box-position ' + appendClassName + " " + isMini} >
      <div className='compose-email-send' id="compose-email-send">
        <div className='header-compose-email'>
          <span> New Message  </span>
          <div className="cursor-pointer flex items-center gap-10" >
            <div onClick={() => { dispatch({ type: "COMPOSE_MIN", payload: true }); }} > {logo.compose_mini}    </div>
            {/* <Popconfirm title="Are you sure you want to save draft?" onConfirm={addToDraft} onCancel={closeCompose} okText="Yes" cancelText="No"> */}
            <div onClick={() => addToDraft()}>{logo.compose_close}</div>
            {/* </Popconfirm> */}
          </div>
        </div>

        <div className='input-compose-msg'>
          <div className='send-alert-body-content connect-wallet-account-div reply-email-user'>
            <div className="email-username reply-user compose">
              {/* <span>Sender:</span> */}
              <input className="reply-user-input compose" placeholder="Sender" id={"sender"} defaultValue={user.name} readonly='true' />
            </div>
            <div className="email-username reply-user compose">
              {/* <span>Recipient:</span> */}
              <input
                className="reply-user-input suggestion-input compose"
                placeholder="Recipient"
                id={"receiver"}
                value={receiverName}
                onChange={(e) => { localStorage.setItem("recipient", e.target.value); handleInputChange(e.target.value, setReceiverName, 'receiver') }}
                onFocus={() => handleFocus('receiver')}
                onBlur={() => {
                  setTimeout(() => {
                    setSuggestions([]);
                  }, 200);
                }}
                autoComplete="off"
              />
              <span className="compose-cc-bcc" onClick={() => setIsCC(!isCC)}> CC </span>
              <span className="compose-cc-bcc" onClick={() => setIsBCC(!isBCC)}> BCC </span>

              {/* Display suggestions for Recipient */}
              {isFocused.receiver && suggestions.length > 0 && (
                <div className="suggestion-list">
                  <div className="suggestion-body">
                    {suggestions.map((email, index) => (
                      <div className="suggestion-list-email" key={index} onClick={() => handleSuggestionClick(email)}>
                        <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div> <div>{email}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {isCC && (
              <div className="email-username reply-user compose">
                {/* <span>CC:</span> */}
                <input
                  className="reply-user-input suggestion-input compose"
                  value={CC}
                  placeholder="CC"
                  onChange={(e) => { localStorage.setItem("cc", e.target.value); handleInputChange(e.target.value, setCC, 'cc') }}
                  onFocus={() => handleFocus('cc')}
                  onBlur={() => {
                    setTimeout(() => {
                      setSuggestions([]);
                    }, 200);
                  }}
                  autoComplete="off"
                />
                {isFocused.cc && suggestions.length > 0 && (
                  <div className="suggestion-list">
                    <div className="suggestion-body">
                      {suggestions.map((email, index) => (
                        <div className="suggestion-list-email" key={index} onClick={() => handleSuggestionClick(email)}>
                          <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div> <div>{email}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {isBCC && (
              <div className="email-username reply-user compose">
                {/* <span>BCC:</span> */}
                <input
                  placeholder="BCC"
                  className="reply-user-input suggestion-input compose"
                  value={BCC}
                  onChange={(e) => { localStorage.setItem("bcc", e.target.value); handleInputChange(e.target.value, setBCC, 'bcc') }}
                  onFocus={() => handleFocus('bcc')}
                  onBlur={() => {
                    setTimeout(() => {
                      setSuggestions([]);
                    }, 200);
                  }}
                  autoComplete="off"
                />
                {isFocused.bcc && suggestions.length > 0 && (
                  <div className="suggestion-list">
                    <div className="suggestion-body">
                      {suggestions.map((email, index) => (
                        <div className="suggestion-list-email" key={index} onClick={() => handleSuggestionClick(email)}>
                          <div className="user-profile-pic">{email.charAt(0).toUpperCase()}</div> <div>{email}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="email-username reply-user compose topic">
              {/* <span>Topic:</span> */}
              <input
                className="reply-user-input"
                id={"subject"}
                placeholder="Topic"
                value={subject}
                onKeyDown={handleTabKey}
                onChange={(e) => { localStorage.setItem("topic", e.target.value); setSubject(e.target.value) }}
              />
            </div>
          </div>
          <Editor placeholder="Write something..." />
        </div>
        {sendLoader &&
          <CompleteLoader isOpen={sendLoader} />
        }
      </div>
    </div>
  )
}

export default ComposeEmail
