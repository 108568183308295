import React, { useState, useEffect } from "react";
import { logo } from "../assets/svg/svg";
import Web3 from 'web3';
import web3Config from '../web3/web3Config.json'; // Importing contract JSON file
import hostContract from '../web3/hostContract.json'; // Importing contract JSON file
import { transactionAction } from "../helper/chain-helper";
import { getPublicKeyValue, web3AccountCheck } from "../helper/web3helper";
import Cookies from "universal-cookie";
import { deleteDatabase } from "../db/db-helper";
import { createWeb2User } from "../service/api-actions"
import { CompleteLoader } from '../modals/common-alert'
import { log } from "../service/api-actions.js";
import { getBrowserDetails } from '../helper/userActivity.js';
import { login } from "../service/api-actions";
import { toast, Bounce } from 'react-toastify';

const web3 = new Web3(window.ethereum);
const hostContractMethods = new web3.eth.Contract(hostContract.contract, web3Config.CONTRACT);
const cookies = new Cookies();
const faucetLink = "https://faucet.ncogchain.earth/";

const Signup = () => {

    const [nameTaken, setNameTaken] = useState(null);
    const [web3Value, setWeb3] = useState(null);
    const [account, setAccount] = useState('');
    const [userName, setUserName] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (window.ethereum) {
            web3AccountCheck(setWeb3, setAccount);
        } else {
            console.log('MetaMask is not installed');
        }
    }, []);


    async function checkAvailability(name) {
        if (!/^[a-zA-Z0-9._]+$/.test(name)) {
            setNameTaken("Sorry, only letters (a-z), numbers (0-9), and periods (. and _) are allowed.");
            return;
        }
        setNameTaken(null);
        const value = await hostContractMethods.methods.getPublicKeyOfUser(name + "@" + web3Config.DOMAIN).call();
        const result = !!value === true ? 'Username Is not Available' : false;
        setNameTaken(result);
    }

    async function confirmUsername(name) {

        if (!name) return null;
        setLoader(true);
        const publicKey = await getPublicKeyValue();
        if (!publicKey) {
            setLoader(false);
            return null;
        }
        const createdDate = new Date();
        const formattedDate = createdDate.toLocaleDateString('en-GB');

        const functionParams = [name, name, publicKey, account, formattedDate];
        const hash = await transactionAction(hostContractMethods, "createAccount", functionParams, account);

        if (hash) {
            await createWeb2User(name + "@" + web3Config.DOMAIN);
            const userObject = { name: name + "@" + web3Config.DOMAIN, wallet: account, publicKey: publicKey };
            const getToken = await login(name + "@" + web3Config.DOMAIN, publicKey);
            if (getToken && getToken.isAuth && getToken.token) {
                cookies.set("userObject", userObject, { path: "/" });
                cookies.set("accessToken", getToken.token, { path: "/" });
                deleteDatabase();
                await log(await getBrowserDetails(), 'signUp');
                window.open(`/inbox`, "_self");
            } else {
                toast.error("Something went wrong", {
                    position: "top-center",
                    transition: Bounce,
                });
                window.open(`/`, "_self");
            }
        }
        setLoader(false);
        return true;
    }

    return (

        <div className='parent-no-layout-container'>
            <div className='sign-up-container'>
                <div className='header-signup-value'>
                    <h4>  Choose Your Unique Username </h4>
                    <span> Choose your unique username, this name will work as your dmail account as well as your Web3ID!</span>
                </div>

                <div className='input-values-sign-up-parent'>
                    <div className={nameTaken === false ? 'input-values-sign-up available' : "input-values-sign-up"} id="signupInput">
                        <div className="input-field-div">
                            <input type="text" id="username" placeholder="Username" onChange={(event) => {
                                let value = event?.target?.value?.trim()?.toLowerCase();
                                document.getElementById("username").value = value;
                                setNameTaken(null);
                                setUserName(value)
                            }} />
                            <span className="domain-input-default"> @{web3Config.DOMAIN} </span>
                            {nameTaken === false && logo.tick_svg}
                            {(nameTaken || nameTaken === null) && logo.check_svg}
                        </div>
                    </div>

                    {nameTaken &&
                        <div className="error-response-success error" >
                            {logo.warning_svg}
                            <span> {nameTaken} </span>
                        </div>
                    }

                    {nameTaken === false &&
                        <div className="error-response-success success" >
                            {logo.tick_svg}
                            <span> Username is Available </span>
                        </div>
                    }

                </div>


                <div className="btn-confirm-sign">

                    {(nameTaken || nameTaken === null) &&
                        <button className={(userName && !nameTaken) ? "confirm-btn-sign name-input-class" : "confirm-btn-sign"} onClick={() => { checkAvailability(userName) }} id="confirmsignin"> Confirm </button>
                    }

                    {nameTaken === false &&
                        <button className="confirm-btn-sign confirm-class" onClick={() => { confirmUsername(userName) }} id="confirmsignin"> Confirm "{userName}@{web3Config.DOMAIN}" </button>
                    }
                </div>

                <button className='faucet-button mt-4' onClick={() => {
                    window.open(faucetLink, "_blank");
                }}><span>Get Free Test Tokens?<br />
                        (NEC Faucet)</span></button>
            </div>
            <CompleteLoader isOpen={loader} />
        </div>
    )
}

export default Signup
