import React from "react";

export const SidebarData = [
  {
    title: "Inbox",
    path: "/inbox",
    icon: "inbox",
    cName: "nav-text",
    id:"inbox"
  },
  {
    title : "Web2 Inbox" ,
    path : "/insecure" ,
    icon : "inbox" ,
    cName : "nav-text",
    id:"web2inbox"
  },
  {
    title: "Sent",
    path: "/sent",
    icon: "sent",
    cName: "nav-text",
    id:"sent"
  },
  {
    title: "Outbox",
    path: "/outbox",
    icon: "outbox",
    cName: "nav-text",
    id:"outbox"
  },
  {
    title: "Draft",
    path: "/draft",
    icon: "draft",
    cName: "nav-text",
    id:"draft"
  },
  {
    title: "Important",
    path: "/important",
    icon: "important",
    cName: "nav-text",
    id:"important"
  },
  {
    title: "Archive",
    path: "/archive",
    icon: "inbox",
    cName: "nav-text",
    id:"archive"
  },
  {
    title : "Trash" ,
    path : "/trash" ,
    icon : "trash" ,
    cName : "nav-text",
    id:"trash"
  }
  // {
  //   title : "DCalendar" ,
  //   path : "/calendar" ,
  //   icon : "key" ,
  //   cName : "nav-text"
  // }
];
