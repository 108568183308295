import React, { useState, useEffect } from 'react';
import CommonMessage from "../components/CommonMessage";
import { web3AccountCheck } from '../helper/web3helper';
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import { useSelector } from 'react-redux';
import db from '../db/db-service';


function Outbox() {
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [web3Contract, setWeb3Contract] = useState('');
  const [allBoxList, setAllBoxList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rendered, setRendered] = useState(false);


  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log('MetaMask is not installed');
    }

    if (web3Contract) {
      setEmail();
    }

  }, []);

  useEffect(() => {
    if (account && web3Value) {
      const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
      setWeb3Contract(contractInstance);
    }
  }, [web3Value, account]);

  const getRefreshMessage = useSelector((state) => state.refreshMessage);
  useEffect(() => {
    if (web3Contract || getRefreshMessage) { setEmail(); }
  }, [web3Contract, getRefreshMessage]);


  const reRender = () => { setEmail(); }


  async function setEmail(loaderRequired = true) {

    try {

      if (!web3Contract) return;

      if (loaderRequired) {
        setLoader(true);
        setRendered(false);
      }
      const indexdb = await db.table('outbox').toArray();
      const reasign = indexdb.map(data => data.json);
      setAllBoxList(reasign);

      if (loaderRequired) {
        setRendered(true);
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (rendered) {
      setLoader(false)
    }
  }, [rendered]);


  return (
    <div className="inbox-container">
      <CommonMessage messageList={allBoxList} pageType={"Outbox"} unreadList={[]} readList={[]} loader={loader} reRender={reRender} />
    </div>
  );
}

export default Outbox;
