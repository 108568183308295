import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
  useLocation
} from "react-router-dom";
import Outbox from "./routes/Outbox";
import Home from "./routes/Home";
import Draft from "./routes/Draft.js";
import Favourite from "./routes/Favourite.js";
import Navbar from "./components/Navbar";
import Signup from "./routes/Signup";
import Login from "./routes/Login";
import WalletConnect from "./routes/WalletConnect.js";
import "./App.css";
import Header from "./components/Header";
import CommonLayout from "./components/CommonLayout.js";
import Sent from "./routes/Sent.js";
// import TourTooltip from "./components/TourTooltip.js";
import { requireAuth } from "./authMiddleware.js";
import Settings from "./routes/Settings.js";
import Folders from "./routes/Folders.js";
import { Provider } from "react-redux";
import store from "./store.js";
import Trash from "./routes/Trash.js";
import Insecure from "./routes/Insecure.js";
import Archive from "./routes/Archive.js";
// import CalendarComponent from "./routes/Calendar.js";
// import CalendarHeader from "./components/CalendarHeader.js";
import "./assets/css/calendar.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notification from "./components/Notification.js";
import 'bootstrap/dist/css/bootstrap.min.css';

// const HeaderWrapper = ({ children }) => {
//   const location = useLocation();
//   const isCalendarPage = location.pathname === '/calendar';
//   return (
//     <>
//       {!isCalendarPage && <Header />}
//       {isCalendarPage && <CalendarHeader />}
//       {children}
//     </>
//   );
// };
const handleToggleSidebar = () => {
    document.body.classList.toggle('leftsidebar-toggle-menu');
  };
const AppLayout = () => (

  <>
    <Navbar />    
    <div className="parent-component-div-align"> 
        <Header toggleSidebar={handleToggleSidebar} />
         <div className="body-component-container"> 
            <Outlet /> 
            <ToastContainer pauseOnFocusLoss={false} />
            <Notification />
            {/* <TourTooltip /> */}
         </div>
    </div>    
  </>
);

const InitialLayout = () => (
  <>
    <div className="parent-component-div-initial-layout"> 
        <Outlet /> 
        <CommonLayout />
        <ToastContainer pauseOnFocusLoss={false} />
        {/* <TourTooltip /> */}
    </div>
  </>
);

const pathArray = [
  {path: "/inbox", element: <Home /> },
  {path: "outbox", element: <Outbox />},
  {path: "draft", element: <Draft />},
  {path: "important", element: <Favourite />},
  {path: "sent", element: <Sent />},
  {path: "settings", element: <Settings />},
  {path: "folders", element: <Folders />},
  {path: "trash", element: <Trash />},
  {path: "insecure", element: <Insecure />},
  {path: "archive", element: <Archive />},
  // {path: "calendar", element: <CalendarComponent />},
]


const router = createBrowserRouter([
  {
    element: <AppLayout />,
    loader: requireAuth, 
    children: pathArray,
  },
  {
    element: <InitialLayout />,
    children: [
      { path: "/signup",  element: <Signup /> },
      { path: "/login",  element: <Login /> },
      { path : "/", element : <WalletConnect /> }
    ]
  }

]);

createRoot(document.getElementById("root")).render(

  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
