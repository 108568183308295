// Loader.js

import React from 'react';
import '../components/styles/loader.css';
import {logo} from "../assets/svg/svg.js"

const Loader = (props) => {
  return (
    <div className={"loader-container" + (props.isMain ? " main-loader-class" : (props.normal ? " normal-loader-div" : ""))}>
        {logo.dmail_loader}
    </div>
  );
}

export default Loader;
