import React, { useState, useEffect } from 'react';
import "../components/styles/messagebox.css"
import NoMessage from './NoMessage';
import Loader from './Loader';
import MessageBoxHeader from './MessageBoxHeader';
import ViewMessageHeader from './ViewMessageHeader';
import Messages from './Messages';
import Encrypt from './emails/Encrypt';
import ComposeEmail from '../components/emails/ComposeEmail.js';
import Decrypt from './emails/Decrypt';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getGeneralSettings } from '../helper/settingsHelper.js';

const CommonMessage = (props) => {
  const dispatch = useDispatch();
  const getCompose = useSelector((state) => state.isCompose);
  const [selectedOption, setSelectedOption] = useState('all');
  const [renderMessageList, setRenderMessage] = useState([]);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isCompose, setIsCompose] = useState(getCompose || false);
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [decryptedContent, setDecryptedContent] = useState(null);
  const [receiverNameValue, setReceiverName] = useState("");
  const [isGrouped, setIsGrouped] = useState(true);


  useEffect(() => {
    const fetchdata = async () => {
      const generalSettings = await getGeneralSettings();
      const isGrouped = generalSettings.isGrouped;
      setIsGrouped(isGrouped);
      switch (selectedOption) {
        case "read":
          groupMessages(props.readList || []);
          break;
        case "unread":
          groupMessages(props.unreadList || []);
          break;
        case "all":
          groupMessages(props.messageList || []);
          break;
        default:
          groupMessages(props.messageList || []);
          break;
      }
    };
    fetchdata();
  }, [props]);

  const groupMessages = (messageList) => {


    if (!isGrouped) {

      return setRenderMessage(messageList);
    }
    const grouped = messageList.reduce((acc, message) => {
      const key = `${message.sender}___${message.subject}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(message);

      return acc;
    }, {});

    const filteredGrouped = Object.fromEntries(
      Object.entries(grouped).filter(([key, messages]) => messages.length > 1)
    );

    const filteredMessageIds = Object.values(filteredGrouped).flat().map(message => message.id)
    const filteredMessageList = messageList.filter(message => !filteredMessageIds.includes(message.id));
    Object.entries(filteredGrouped).forEach(([key, value]) => {
      filteredMessageList.push({ groupKey: key, date: value[0].date, data: value });
    });
    filteredMessageList.sort((a, b) => new Date(b.date) - new Date(a.date));
    setRenderMessage(filteredMessageList);
  };

  const currentMessage = () => {
    switch (selectedOption) {
      case "read":
        return props.readList || []
      case "unread":
        return props.unreadList || [];
      case "all":
        return props.messageList || [];
      default:
        return props.messageList || [];
    }
  };

  const useSelectordata = useSelector((state) => state.searchTerm);
  useEffect(() => {
    if (useSelectordata && messageList.length) {
      const retrivedValue = [];
      for (let data of currentMessage()) {
        if (data.subject?.toLowerCase().includes(useSelectordata.toLowerCase()) || (data.header ? JSON.parse(data.header)?.to?.join(',').includes(useSelectordata.toLowerCase()) : false) ||
          data.sender?.toLowerCase().includes(useSelectordata.toLowerCase())) {
          retrivedValue.push(data);
        }
      }
      groupMessages(retrivedValue);
    } else {
      groupMessages(currentMessage());
    }
  }, [useSelectordata])

  const getFilters = useSelector((state) => state.filterObject);
  const triggerFilter = useSelector((state) => state.trigger);

  useEffect(() => {
    if (!currentMessage()) return;
    if (!getFilters.from && !getFilters.to && !getFilters.subject && !getFilters.dateRange) return groupMessages(currentMessage());
    const formatedDate = (getFilters.dateRange && getFilters.dateRange.length == 2) ? getFilters.dateRange.map(date => date.format('YYYY-MM-DD')) : false;
    const filteredMessages = currentMessage().filter(data => {
      const createdAt = moment(data.created_at) ?? false;
      const header = data.header ? JSON.parse(data.header) : {};
      const isfrom = (getFilters.from.trim() && header.sender) ? header.sender.toLowerCase().includes(getFilters.from.trim().toLowerCase()) : true;
      const isto = (getFilters.to.trim() && header.to) ? header.to.concat(header.cc, header.bcc).join(',').includes(getFilters.to.trim().toLowerCase()) : true;
      const isSubject = (getFilters.subject.trim() && data.subject.trim()) ? data.subject.toLowerCase().includes(getFilters.subject.trim().toLowerCase()) : true;
      const isWithinDateRange = (formatedDate && createdAt) ? createdAt.isBetween(formatedDate[0], formatedDate[1], 'days', '[]') : true;
      return isfrom && isto && isSubject && isWithinDateRange;
    });
    groupMessages(filteredMessages);
  }, [props, triggerFilter]);

  const refreshCount = useSelector((state) => state.refreshCount);
  useEffect(() => {
    if (isDecrypted) {
      dispatch({ type: "REFRESH_COUNT", payload: !refreshCount });
    }
  }, [isDecrypted]);

  const filterType = useSelector((state) => state.filterType);
  const parentClassName = filterType ? "sort-added" : "sort-inactive"

  const draftData = useSelector((state) => state.draftData);
  const outBoxData = useSelector((state) => state.outBoxData);
  const outBoxIndex = useSelector((state) => state.outBoxIndex);
  const selectMessage = useSelector((state) => state.selectedMessage);

  useEffect(() => {
    if (draftData) {
      dispatch({ type: "IS_COMPOSE", payload: true })
    }

  }, [draftData]);

  useEffect(() => {
    if (outBoxData) {
      dispatch({ type: "IS_COMPOSE", payload: true })
      dispatch({ type: "OUTBOX", payload: outBoxIndex, value: false });
    }
  }, [outBoxData]);

  React.useEffect(() => {
    setIsCompose(getCompose);
  }, [getCompose]);


  const messageList = props.messageList || [];
  const readList = props.readList || [];
  const unreadList = props.unreadList || [];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    switch (event.target.value) {
      case "read":
        groupMessages(readList);
        break;
      case "unread":
        groupMessages(unreadList);
        break;
      case "all":
        groupMessages(messageList);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSelectedMessageIndex(null);
    setIsDecrypted(null);
    setDecryptedContent(null);
    reRender();
  }, [selectMessage]);


  async function openMessage(message) {
    setSelectedMessage(message);
  }

  async function reRender() {
    if (props.reRender) props.reRender();
  }

  const type = props.pageType || "Inbox";
  const messageViewStatus = !(selectedMessageIndex === null);
  const isMessageLoad = !props.loader && messageViewStatus;

  return (
    <div className={'common-message-containder'}>

      {selectedMessageIndex === null &&
        < MessageBoxHeader handleOptionChange={handleOptionChange} reRender={reRender} selectedOption={selectedOption} messageList={messageList} readList={readList} unreadList={unreadList} pageType={type} groupedmessage={renderMessageList} groupmessage={isGrouped} />
      }

      {messageViewStatus &&
        <ViewMessageHeader messageList={renderMessageList} reRender={reRender} index={selectedMessageIndex} setIndex={setSelectedMessageIndex} setIsDecrypted={setIsDecrypted} setDecryptedContent={setDecryptedContent} openMessage={openMessage} isDecrypted={isDecrypted} />
      }

      <div className={isMessageLoad ? 'message-box-container message-view-content' : 'message-box-container' + ` ${parentClassName}`} >
        {props.loader && <Loader isMain={true} />}
        {!props.loader && !messageViewStatus && renderMessageList.length != 0 && <Messages messageList={renderMessageList} setSelectedMessageIndex={setSelectedMessageIndex} openMessage={openMessage} setIsDecrypted={setIsDecrypted} setDecryptedContent={setDecryptedContent} pageType={type} reRender={reRender} />}
        {!props.loader && messageViewStatus && !isDecrypted && renderMessageList.length != 0 && <Encrypt reRender={reRender} messageList={renderMessageList} selectedMessage={selectedMessage} setSelectedMessageIndex={setSelectedMessageIndex} selectedIndex={selectedMessageIndex} openMessage={openMessage} setIsDecrypted={setIsDecrypted} setDecryptedContent={setDecryptedContent} pageType={type} />}
        {!props.loader && messageViewStatus && isDecrypted && renderMessageList.length != 0 && <Decrypt messageList={renderMessageList} selectedMessage={selectedMessage} setSelectedMessageIndex={setSelectedMessageIndex} selectedIndex={selectedMessageIndex} openMessage={openMessage} setIsDecrypted={setIsDecrypted} pageType={type} decryptedContent={decryptedContent} reRender={reRender} />}
        {!props.loader && renderMessageList.length === 0 && <NoMessage pageType={type} />}
      </div>


      {isCompose &&
        <div >
          <ComposeEmail setIsCompose={setIsCompose} reRender={reRender} receiverName={receiverNameValue} pageType={type} />
        </div>
      }

    </div>
  )
}

export default CommonMessage
