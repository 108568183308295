import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { logo } from '../../assets/svg/svg'
import { truncateString } from '../../helper/eventHelper';
import contractData from '../../web3/hostContract.json';
import config from '../../web3/web3Config.json';
import { markMessageAsImportant, web3AccountCheck } from '../../helper/web3helper';
import Cookies from "universal-cookie";
import { transactionAction } from '../../helper/chain-helper';
import { convertToLocalDate, optionalValidation, getFirstLetterUppercase, convertToLocalDateforWeb2 } from '../../helper/object-validation-helper';
import { syncIndexedDB } from '../../db/indexed-helper.js';
import { CompleteLoader, ReplyModal } from '../../modals/common-alert.js';
import { getFile } from '../../helper/attachmentHelper.js';
import { updateInsecureMail, log, getEncryptedMessage } from "../../service/api-actions.js";
import { useSelector } from 'react-redux';
import { deleteEmails } from '../../helper/emailHelper.js';
import { Tooltip, Popconfirm } from 'antd'
import hostContract from '../../web3/hostContract.json';
import Swal from 'sweetalert2'

const cookies = new Cookies();

const contractAddress = config.CONTRACT;
const web3 = new Web3(window.ethereum);
const contractMethods = new web3.eth.Contract(contractData.contract, contractAddress);


const Encrypt = (props) => {
  const [decryptedContent, setDecryptedContent] = useState(null);
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [loader, setLoader] = useState(false);
  const [user] = useState(cookies.get("userObject"));
  const getFolderIndex = useSelector((state) => state.folderIndex);
  const [messageList, setMessageList] = useState([]);
  const [replyModal, setReplyModal] = useState(false);
  const [messageType, setMessageType] = useState(null);
  let messageValue = null;
  let attachmentValue = null;

  if (decryptedContent) {
    try {
      messageValue = JSON.parse(decryptedContent);
      attachmentValue = messageValue.attachment || null;
    } catch (error) {
      console.error('Error parsing decrypted content:', error);
    }
  }

  const { selectedMessage } = props;

  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log('MetaMask is not installed');
    }
  }, []);

  useEffect(() => {
    setMessageList(props.messageList);
  }, [props.messageList])

  const encryptedMsg = '0x7b2276657273696f6e223a227832353531392d7873616c736132302d706f6c7931333035222c226e6f6e6365223a22716f534169636c6368535a5577455a475541546a6d3742574e4f7a3668346636222c22657068656d5075626c69634b6579223a2267757a68436b4f793148786332466239524b57774d647a596c72554b3763525a4e396c6f5361582f5542413d222c2263697068657274657874223a2248486738665745645a2f352b752b74';

  async function getDecryptValue(msg, reply = false) {
    try {
      const data = await getEncryptedMessage(msg.encryptedMsg);
      const encryptedMsg = data.returnValue ? data.returnValue : msg.encryptedMsg;
      const functionNames = {
        "Sent": "markSentEmailAsRead",
        "Inbox": "markEmailAsRead",
      }

      const accounts = await window.ethereum.request({ method: 'eth_accounts' });

      if (accounts.length) {
        try {
          const decryptedMsg = await window.ethereum.request({ method: 'eth_decrypt', params: [encryptedMsg, accounts[0]] });
          setDecryptedContent(decryptedMsg);
          props.setDecryptedContent(decryptedMsg);
          if (selectedMessage.emailType && selectedMessage.emailType === 'Senttrash') {
            syncIndexedDB(selectedMessage.id, decryptedMsg, 'Senttrash');
          } else {
            syncIndexedDB(selectedMessage.id, decryptedMsg, props.pageType);
          }
          const functionParams = [user.name, selectedMessage.id];
          if ((props.pageType == "Trash" || props.pageType == "Folders") && !selectedMessage.isRead && selectedMessage.emailType && (selectedMessage.emailType === 'Senttrash' || selectedMessage.emailType === 'sent')) {
            await transactionAction(contractMethods, 'markSentEmailAsRead', functionParams, accounts[0]);
          } else {
            if (!selectedMessage.read && props.pageType !== "Insecure") {
              const functionName = functionNames[props.pageType] || "markEmailAsRead";
              await transactionAction(contractMethods, functionName, functionParams, accounts[0]);
            }
          }
          if (props.pageType == "Insecure" && !selectedMessage.isRead) {
            await updateInsecureMail('isRead', selectedMessage.id, "");
          }
          if (!reply) {
            props.setIsDecrypted(true);
          }

        } catch (error) {
          setLoader(false);
          await log(JSON.stringify(error));
          console.error(error);
          return null;
        } finally {
          setLoader(false);
        }
      }

      setLoader(false);
      return null;
    } catch (error) {
      setLoader(false);
      console.error(error);
    } finally {
      setLoader(false);
    }
  }

  const getDecryptedfile = async (attachment) => {
    const filesPromises = attachment.map(async (key, index) => {
      const value = await getFile(Object.keys(key)[0], Object.values(key)[0]);
      return value;
    });
    const files = await Promise.all(filesPromises)
    return files;
  };

  const actionClick = async (type) => {
    if (type === 'Starred') {
      setLoader(true);
      if (selectedMessage && selectedMessage.id && (props.pageType == 'Inbox' || props.pageType == 'Important')) {
        const contractMethods = new web3Value.eth.Contract(hostContract.contract, contractAddress);
        const obj = {
          id: parseInt(selectedMessage.id),
          isStarred: selectedMessage.isStarred
        }
        await markMessageAsImportant(contractMethods, user.name, obj, account);
        props.reRender();
      } else if (selectedMessage && selectedMessage.id && props.pageType == 'Insecure') {
        await updateInsecureMail('fav', selectedMessage.id, "");
        props.reRender();
      }
      setLoader(false);
      return true;
    }
    if (!selectedMessage.decryptedMsg) {
      setLoader(true);
      setMessageType(type);
      await getDecryptValue(selectedMessage, true);
      setReplyModal(true)
    }
  }

  const isStarred = optionalValidation(selectedMessage, "isStarred");
  const starSvg = isStarred ? logo.star_svg_fill : logo.star_svg_outline;
  const toOrFrom = props.pageType == "Sent" ? 'To :' : 'From :';
  const toOrFromName = props.pageType == "Sent" ? JSON.parse(selectedMessage.receiver).to[0] : selectedMessage.sender;

  return (
    <div className='encrypted-message-parent-ele'>
      <div className='encrypted-message-header-ele' >

        <div className='topic-ele'>
          <p> Topic :</p>
          <h2> {selectedMessage.subject} </h2>
        </div>

        <div className='actions'>
          <Tooltip title="Starred"><div className="cursor-pointer" onClick={() => { actionClick("Starred"); }}> {starSvg} </div></Tooltip>
          <Tooltip title="Reply all"><div className="cursor-pointer" onClick={() => { actionClick("Reply"); }}> {logo.reply_all_svg} </div></Tooltip>
          <Tooltip title="Reply"><div className="cursor-pointer" onClick={() => { actionClick("Reply"); }}> {logo.reply_svg} </div></Tooltip>
          <Tooltip title="Forward"><div className="cursor-pointer" onClick={() => { actionClick("Forward"); }}> {logo.forward_svg} </div></Tooltip>
          <Tooltip title="Delete">
            <div className='cursor-pointer' onClick={async () => {
              Swal.fire({
                text: "Are you sure you want to delete?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1677ff",
                cancelButtonColor: "#ffffff",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                customClass: "compose-sweet-alert"
              }).then(async (result) => {
                if (result.isConfirmed) {
                  setLoader(true);
                  const folderIndex = getFolderIndex;
                  await deleteEmails(selectedMessage, selectedMessage.id, props.selectedIndex, messageList, props.pageType, folderIndex);
                  props.reRender();
                  props.setSelectedMessageIndex(null);
                  setLoader(false);
                }
              });
            }}>
              {logo.msg_delete_icon}
            </div>
          </Tooltip>
        </div>

      </div>

      <div className='encrypted-message-header-ele' >
        <div className='topic-ele'>
          <p>{toOrFrom}</p>
          <div className='user-details-section child-ele-encrypt'>
            <div className='user-profile-pic'>{getFirstLetterUppercase(toOrFromName.split("@")[0])}</div>
            <div className='user-profile-name'>
              <div className='user-profile-username'>{toOrFromName.split("@")[0]}</div>
              <div className='user-profile-useremail'>{toOrFromName}</div>
            </div>

          </div>

        </div>

        <div className='actions date-encrypt'>
          <h4> {props.pageType === 'Insecure' ? convertToLocalDateforWeb2(selectedMessage.date) : convertToLocalDate(selectedMessage.date)}</h4>
          <Tooltip title="Decrypt Message"><div className='cursor-pointer' onClick={() => { setLoader(true); getDecryptValue(selectedMessage); }}> {logo.key_lock_svg} </div></Tooltip>
        </div>

      </div>

      <div className='encrypted-msg-content'>

        <div className='encryption-content-div'>
          <div className='encrypted-alert'>
            <div className='emcrypted-child-content'>
              <div> {logo.view_unlock_svg} </div>
              <div className='encrypted-heading'> Encrypted content! </div>
              <div className='encrypted-content-org'> {encryptedMsg} </div>
              <div> <button className='btn-encrypt-view-msg cursor-pointer' onClick={() => {
                setLoader(true);
                getDecryptValue(selectedMessage);
              }}> Decrypt Message {logo.key_unlock_svg} </button> </div>

            </div>
          </div>
        </div>

      </div>
      <CompleteLoader isOpen={loader} />
      {replyModal &&
        <ReplyModal isModalOpen={replyModal} close={() => { setReplyModal(false); props.setIsDecrypted(true); }} attachmentValue={attachmentValue} msg={selectedMessage} decryptedMessage={messageValue.message} messageType={messageType} pageType={props.pageType} />
      }
    </div>
  )
}

export default Encrypt
