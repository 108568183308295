import React from 'react'
import { logo } from '../assets/svg/svg'
import constant from "../constants/constants.json"

const NoMessage = (props) => {  


  return (
    <div className='no-message-div'>
                <div className='search-icon-no-message'> {logo.svg_mag_glass} </div>
                <div className='no-message-yet-ele'>No Messages yet! </div>
                {constant.message_box[props.pageType.toLowerCase()] &&                 
                     <div className='no-message-yet-ele-para'> {constant.message_box[props.pageType.toLowerCase()]} </div>                
                }
    </div>
  )
}

export default NoMessage
