import config from "../web3/web3Config.json";

export const generateEvent = async (icsParams, user) => {
    const fdate = icsParams.fromTime;
    const todate = icsParams.toTime;
    let location = "";
    let image = "";
    const username = user.split("@")[0]
    icsParams.list.forEach((event) => {
        if (event.key === "location") {
            location = event.value;
        }
        if (event.key === "image") {
            image = event.value;
        }
    });
    const timeZone = "Etc/GMT";

    const event = {
        uid: icsParams.uid,
        start: {
            dateTime: fdate,
            timeZone: timeZone,
        },
        end: {
            dateTime: todate,
            timeZone: timeZone,
        },
        title: icsParams.title,
        description: icsParams.description,
        location: location,
        organizer: {
            name: "Organizer",
            email: user,
        },
        list: icsParams.list,
        username,
    };
    const icsContent = generateICS(event);

    async function generateICS(event) {
        const { uid, start, end, title, description, location, organizer, list, username } = event;
        const attendees = list
            .filter(item => item.key === 'guest')
            .map(guest => ({
                name: guest.value.split('@')[0],
                email: guest.value,
                role: 'REQ-PARTICIPANT',
                partstat: 'NEEDS-ACTION',
                rsvp: 'TRUE',
                cutype: 'INDIVIDUAL'
            }));
        const rule = await generateRecurrenceRule(list);
        const updatedDescriptions = formatDescriptionForHTML(description);
        return `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//DMail //Your Product//EN
METHOD:REQUEST
BEGIN:VEVENT
UID:${uid}
DTSTART;TZID=${start.timeZone}:${start.dateTime}
DTEND;TZID=${end.timeZone}:${end.dateTime}
SUMMARY:${title}
DESCRIPTION:${updatedDescriptions}
LOCATION:${location}
${rule ? rule : ``}
ORGANIZER;CN=${username}:MAILTO:${username}@${config.VERFIED_DOMAIN}
ATTENDEE;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;RSVP=TRUE;CN=${username}@${config.VERFIED_DOMAIN}:mailto:${username}@${config.VERFIED_DOMAIN}
${attendees.map(attendee => `ATTENDEE;CUTYPE=${attendee.cutype || 'INDIVIDUAL'};ROLE=${attendee.role || 'REQ-PARTICIPANT'};PARTSTAT=${attendee.partstat};RSVP=${attendee.rsvp || 'TRUE'};CN=${attendee.name}:mailto:${attendee.email}`).join('\n')}
END:VEVENT
BEGIN:VTIMEZONE
TZID:${start.timeZone}
X-LIC-LOCATION:${start.timeZone}
BEGIN:STANDARD
TZOFFSETFROM:+0000
TZOFFSETTO:+0000
TZNAME:GMT
DTSTART:${start.dateTime}
END:STANDARD
END:VTIMEZONE
END:VCALENDAR
`;
    }
    return icsContent;
};

export async function parseICalendar(icsContent) {
    const lines = icsContent.split(/\r?\n/);
    const eventObject = { event: {}, timezones: {}, method: null, subEvent: {} };
    let currentEvent = {};
    let currentAttendees = [];
    let currentKey = '';
    let currentValue = '';
    let currentMetadata = {};
    let currentTimezone = '';
    let eventCount = 0;

    lines.forEach(line => {
        // Handle continued lines
        if (line.startsWith(' ') || line.startsWith('\t')) {
            currentValue += line.trim();
        } else {
            if (currentKey) {
                if (currentKey === 'ATTENDEE') {
                    currentAttendees.push({ value: currentValue, metadata: currentMetadata });
                } else {
                    currentEvent[currentKey] = currentMetadata && Object.keys(currentMetadata).length
                        ? { value: currentValue, metadata: currentMetadata }
                        : currentValue;
                }
            }

            // Reset key, value, and metadata for the next line
            currentMetadata = {};
            const [keyPart, value] = line.split(/:(.+)/);
            const [mainKey, ...metaParts] = keyPart.split(';');
            currentKey = mainKey.trim();
            currentValue = value ? value.trim() : '';

            // Handle metadata, if any
            metaParts.forEach(part => {
                const [k, v] = part.split('=');
                if (k && v) currentMetadata[k.trim()] = v.trim();
            });
        }

        // Check for timezone boundaries
        if (line.startsWith('BEGIN:VTIMEZONE')) {
            currentTimezone = {};
        } else if (line.startsWith('END:VTIMEZONE')) {
            eventObject.timezones[currentKey] = currentTimezone; // Store the timezone
            currentTimezone = ''; // Reset currentTimezone for the next one
        } else if (currentTimezone) {
            // Process timezone lines
            if (currentKey === 'TZID') {
                currentTimezone.TZID = currentValue;
            } else {
                currentTimezone[currentKey] = { value: currentValue, metadata: currentMetadata };
            }
        }

        // Check for event boundaries
        if (line.startsWith('BEGIN:VEVENT')) {
            eventCount++;
            currentEvent = {};
            currentAttendees = [];
        } else if (line.startsWith('END:VEVENT')) {
            if (currentAttendees.length) currentEvent.ATTENDEES = currentAttendees;
            if (eventCount === 1) {
                eventObject.event = { ...currentEvent };
            } else {
                eventObject.subEvent = { ...currentEvent };
            }
            currentEvent = {};
            currentAttendees = [];
        }
        if (currentKey === 'METHOD') {
            eventObject.method = currentValue;
        }
    });

    // Final key-value processing for last line
    if (currentKey) {
        if (currentKey === 'ATTENDEE') {
            currentAttendees.push({ value: currentValue, metadata: currentMetadata });
        } else {
            currentEvent[currentKey] = currentMetadata && Object.keys(currentMetadata).length
                ? { value: currentValue, metadata: currentMetadata }
                : currentValue;
        }
    }

    return eventObject;
}

function generateRecurrenceRule(list) {
    const repeatEvent = list
        .filter((item) => item.key === "repeatEvent")
        .map((repeat) => repeat.value);
    const customRepeatEvent = list
        .filter((item) => item.key === "customRepeatEvent")
        .map((repeat) => repeat.value);
    const { object: customRepeatObject } = parseEvent(customRepeatEvent);
    const { object: repeatObject } = parseEvent(repeatEvent);
    if (!repeatEvent.length) return ""; // No repeat events, return empty rule
    const repeatEventSplit = repeatEvent[0]?.split("_").filter(Boolean);
    let rule = "";
    if (repeatEventSplit[0] === "custom") {
        // Custom repeat logic
        rule = `RRULE:FREQ=${customRepeatObject?.unit?.toUpperCase() || "DAILY"};WKST=SU;INTERVAL=${customRepeatObject?.interval || 1};`;
        if (customRepeatObject?.count) rule += `COUNT=${customRepeatObject.count};`;
        if (customRepeatObject?.byday) rule += `BYDAY=${customRepeatObject.byday};`;
        if (customRepeatObject?.customMonth) {
            if (isNaN(customRepeatObject?.customMonth)) {
                // Handle custom month with week and day
                const [week, day] = customRepeatObject.customMonth.match(/(\d+)(\w+)/)?.slice(1) || [];
                if (week && day) rule += `BYDAY=${week}${day.slice(0, 2).toUpperCase()};`;
            } else {
                // Handle custom month with numeric day
                rule += `BYMONTHDAY=${customRepeatObject.customMonth};`;
            }
        }
        if (customRepeatObject?.endDate) rule += `UNTIL=${customRepeatObject.endDate};`;
    } else {
        // Standard repeat logic
        rule = `RRULE:FREQ=${repeatEventSplit[0].toUpperCase()};`;
        if (repeatEventSplit.length === 2) {
            const secondPart = repeatEventSplit[1];
            if (/\d/.test(secondPart)) {
                // Handle week and day (e.g., 2SU)
                const [week, day] = secondPart.match(/(\d+)(\w+)/)?.slice(1) || [];
                if (week && day) rule += `BYDAY=${week}${day.slice(0, 2).toUpperCase()};`;
            } else if (/,/.test(secondPart)) {
                // Handle multiple days (e.g., MO,TU)
                rule += `BYDAY=${secondPart};`;
            } else {
                // Handle single day (e.g., SU)
                rule += `BYDAY=${secondPart.slice(0, 2).toUpperCase()};`;
            }
        }
        if (repeatObject?.endDate) rule += `UNTIL=${repeatObject.endDate};`;
    }
    return rule;
}

function parseEvent(event) {
    const parsedValues = [];
    const parsedObject = {};
    if (event?.length) {
        event[0]?.split("_").forEach((item) => {
            if (item) {
                const [key, value] = item.split("~");
                if (key && value) {
                    parsedValues.push({ [key]: value });
                    parsedObject[key] = value;
                }
            }
        });
    }
    return { values: parsedValues, object: parsedObject };
}

function formatDescriptionForHTML(description) {
    if (description) {
        const escapeHTML = (str) => {
            return str?.replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        };
        return escapeHTML(description).replace(/\n/g, '<br>');
    } else {
        return ''
    }
}