import { getS3File, stringToFile } from "../service/api-actions";
import db from '../db/db-service.js';
import { icon } from '../assets/svg/attachment-svg.js';

const fileTypes = {
    png: 'image/jpeg',
    gif: 'image/jpeg',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    jfif: 'image/jpeg',
    pdf: 'application/pdf',
    svg: 'image/svg+xml',
    PDF: 'application/pdf',
    csv: 'text/csv',
    zip: 'application/zip',
    rar: 'application/octet-stream',
    xlsx_listing: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    csv_listing: "application/vnd.ms-excel",
    sizeLimit : 15 , 
    bytes : 1024
}

export const getFile = async (fileName, attachmentUrl) => {
    const split = attachmentUrl.split('/');
    const attachment = await db.attachment.where('s3url').equals(split[split.length - 1]).first();
    if (attachment) {
      // const blobUrl = window.URL.createObjectURL(attachment.file);  if need to file as a blob
      return attachment.file;
      // window.open(blobUrl);
    } else {  
    const res = await getS3File(fileName, attachmentUrl);
  
    const dotSplit = fileName.split('.');
    const extenstion = dotSplit[dotSplit.length - 1];
    const fileType = fileTypes[extenstion];

    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    const encryptedMsg = res.data;
  
    if (accounts.length) {
      const decryptedMsg = await window.ethereum.request({ method: 'eth_decrypt', params: [encryptedMsg, accounts[0]] });
      const blob = new Blob([decryptedMsg], { type: 'text/plain' });
      const file = new File([blob], 'textfile.txt', { type: 'text/plain' });
      const response = await stringToFile(file, fileName);


      let newBlob = new Blob([response.data], { type: fileType });
      const blobUrl = window.URL.createObjectURL(newBlob);
      const newFile = new File([newBlob], fileName, { type: fileType });
      const Object = { name: fileName, s3url: split[split.length - 1], file: newFile };
      await db.table('attachment').add(Object);
      return newFile;
      // window.open(blobUrl);
    }
    }
};



export const downloadFile = async (fileName, attachmentUrl) => {
  try {
    const response = await getS3File(fileName, attachmentUrl);
    const encryptedMessage = response.data;
    const attachmentKey = attachmentUrl.split('/').pop();
    const attachment = await db.attachment.where('s3url').equals(attachmentKey).first();
    if (attachment) {
      const blobUrl = window.URL.createObjectURL(attachment.file);

      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);

      return true;
    }
    const userAccounts = await window.ethereum.request({ method: 'eth_accounts' });
    if (userAccounts.length) {
      const decryptedMessage = await window.ethereum.request({ method: 'eth_decrypt', params: [encryptedMessage, userAccounts[0]] });
      const blob = new Blob([decryptedMessage], { type: 'text/plain' });
      const file = new File([blob], 'decryptedFile.txt', { type: 'text/plain' });
      const uploadResponse = await stringToFile(file, fileName);

      const downloadUrl = window.URL.createObjectURL(new Blob([uploadResponse.data], { type: uploadResponse.headers['content-type'] }));
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(downloadUrl);
    }
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export const getFileIcon = async (fileType) => {
  const dotSplit = fileType.split('.');
  const extenstion = dotSplit[dotSplit.length - 1].toLowerCase();
  switch (extenstion) {
      case 'pdf':
          return icon.pdf_svg;
      case 'jpeg':
      case 'jpg':
          return icon.jpg_svg;
      case 'png':
          return icon.png_svg;
      case 'gif':
          return icon.gif_svg;
      case 'docx':
          return icon.doc_svg;
      case 'xls':
      case 'xlsx':
      case 'csv':
          return icon.xls_svg;
      case 'svg':
          return icon.svg;
      case 'txt':
          return icon.text_svg;
      default:
          return icon.default_file_svg;
  }
}
