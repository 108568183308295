import React, { useState, useRef, useEffect } from 'react'
import { logo } from '../assets/svg/svg'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2'
import { updateInsecureMail } from '../service/api-actions'
import { CompleteLoader } from "../modals/common-alert"
const MessageBoxHeader = (props) => {
  const { pageType, groupedmessage, groupmessage } = props;
  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState('all');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();
  const useSelectordata = useSelector((state) => state.filterType);
  const selectedValue = useSelector((state) => state.selectedType);
  const dropdownRef = useRef(null);
  const [loader, setloader] = useState(false)
  const pagiClickRight = useSelector((state) => state.pagiClickRight);
  const pagiClickLeft = useSelector((state) => state.pagiClickLeft);
  const currentPage = useSelector((state) => state.currentPage);
  const messagesPerPage = useSelector((state) => state.messagesPerPage);
  const checkbox = useRef(null);
  const checkboxRef = useRef(null);
  const type = props.pageType === 'Insecure' ? 'Web2 Inbox' : props.pageType;
  const handleOptionChange = props.handleOptionChange;
  const selectedOption = props.selectedOption;
  const messageList = props.messageList;
  const location = useLocation();
  const readList = props.readList;
  const unreadList = props.unreadList;
  const isVisibleFilter = !["Draft", "Outbox"].includes(props.pageType);
  const checkBoxType = {
    1: "All", 2: "All Read", 3: "All Unread", 4: "All with Star"
  };
  useEffect(() => {
    dispatch({ type: "MESSAGE_PER_PAGE", payload: 50 });
    dispatch({ type: "CURRENT_PAGE", payload: 1 })
  }, [location.pathname, dispatch]);
  const returnHtmlCheckBox = () => {
    return (
      <div className='position-uncover-checkbox' ref={checkbox}>
        {Object.keys(checkBoxType).map((key) => (
          <div key={key}>
            <input type="checkbox" id={key} value={key} checked={selectedValue === parseInt(key, 10)} onClick={handleSelect(key)} />
            <label htmlFor={key}>{checkBoxType[key]}</label>
          </div>
        ))}
      </div>
    );
  };

  const handleSelect = (value) => () => {

    let parseValue = parseInt(value, 10);
    if (parseValue === selectedValue) {
      parseValue = null;
    }
    dispatch({ type: "SELECT_TYPE", payload: parseValue });
  };

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (checkbox.current && !checkbox.current.contains(event.target) &&
      checkboxRef.current && !checkboxRef.current.contains(event.target)) {
      setIsCheckOpen(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  const getTotalLength = () => {
    if (selectedOption === 'all') {
      dispatch({ type: "BULK_DELETE", payload: messageList })
      if (groupmessage) {
        return groupedmessage.length
      }
      else {
        return messageList.length
      }
    };
    if (selectedOption === 'read') return readList.length;
    if (selectedOption === 'unread') return unreadList.length;
  };
  const handleDropdownSelect = (value) => () => {
    setSelectedDropdownOption(value);
    setIsDropdownOpen(false);
    handleOptionChange({ target: { value } });
  };
  const bulkDelete = async (message, msgId, index) => {
    Swal.fire({
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1677ff",
      cancelButtonColor: "#ffffff",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "compose-sweet-alert"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setloader(true)
        const batchProcess = async (updates, batchSize) => {
          const updatedresponse = []
          updates.forEach((data) => {
            updatedresponse.push({
              "id": data.messageId,
              value: 1
            });
          });
          const chunks = chunkArray(updatedresponse, batchSize);
          for (const chunk of chunks) {
            let value = chunk
            await updateInsecureMail("bulkdelete", "", value);
          }
        };
        await batchProcess(messageList, messagesPerPage);
        props.reRender();
        setloader(false)
      }
    });
    const chunkArray = (array, size) => {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };


  }
  return (<>
    <div className='box-common-header box-header-type' >
      <div className='page-type'>
        <div className='page-type-check-box'>
          <input type="checkbox" id="html" value='1' onClick={handleSelect(1)} checked={selectedValue === 1} />
        </div>
        <div className='page-type'>
          <div className='page-type-arrow cursor-pointer' ref={checkboxRef} onClick={() => setIsCheckOpen(!isCheckOpen)}>
            {logo.down_arrow_box}
          </div>
          {type}
        </div>
        {isCheckOpen && returnHtmlCheckBox()}
        <div className='menu-dot-message-header'> {logo.svg_menu_dot} </div>
      </div>
      <div className="flex items-center">
        {isVisibleFilter && (
          <div className={`show-checkbox-box-type ${isMobileView ? 'dropdown-show-checkbox-box-type' : ''}`} ref={dropdownRef}>
            {isMobileView ? (
              <div className='show-btn' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                <p>
                  {selectedDropdownOption === 'all' ? <>All <span>({messageList.length})</span></> :
                    selectedDropdownOption === 'read' ? <>Read <span>({readList.length})</span></> :
                      <>Unread <span>({unreadList.length})</span></>}
                </p>
                <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1.25L3.5 3.75L6 1.25" stroke="#A3ABAD" stroke-width="2" strokeLinecap="round" />
                </svg>
              </div>
            ) : (
              <>
                <div className='show-btn show-text'>
                  <span> Show : </span>
                </div>
                <div className='show-btn'>
                  <label className='label-radio'>
                    <input type="radio" className="input-radio on" name="message-box" value="all" checked={selectedOption === 'all'} onChange={handleOptionChange} />
                    <span className={selectedOption === 'all' ? 'active' : ''}> All <span>({messageList.length})</span> </span>
                  </label>
                  <span className="pipe-symbol"> | </span>
                </div>
                {pageType !== "Sent" &&
                  <div className='show-btn'>
                    <label className='label-radio'>
                      <input type="radio" className="input-radio on" name="message-box" value="read" checked={selectedOption === 'read'} onChange={handleOptionChange} />
                      <span className={selectedOption === 'read' ? 'active' : ''}> Read <span>({readList.length})</span> </span>
                    </label>
                    <span className="pipe-symbol"> | </span>
                  </div>
                }
                {pageType !== "Sent" &&
                  <div className='show-btn'>
                    <label className='label-radio'>
                      <input type="radio" className="input-radio on" name="message-box" value="unread" checked={selectedOption === 'unread'} onChange={handleOptionChange} />
                      <span className={selectedOption === 'unread' ? 'active' : ''}> Unread <span>({unreadList.length})</span> </span>
                    </label>
                  </div>
                }
              </>
            )}
            {isMobileView && (
              <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
                <div className='show-btn' onClick={handleDropdownSelect('all')}>
                  All <span>({messageList.length})</span>
                </div>

                <div className='show-btn' onClick={handleDropdownSelect('read')}>
                  Read <span>({readList.length})</span>
                </div>
                <div className='show-btn' onClick={handleDropdownSelect('unread')}>
                  Unread <span>({unreadList.length})</span>
                </div>

              </div>
            )}
          </div>
        )}
        {selectedValue && <div onClick={bulkDelete}>
          <DeleteOutlined color="#767676" />
        </div>}

        {isVisibleFilter &&
          <div className='sort-icon-action-01' onClick={() => {
            // dispatch({ type: "FILTER_TYPE", payload: !useSelectordata })
          }} >
            {/* {logo.filter_icon_svg} */}

            <div className="pagination-controls"><span className='pagination-list-length text-truncate'> {((currentPage * messagesPerPage) - messagesPerPage) + 1} - {(currentPage * messagesPerPage) > getTotalLength() ? getTotalLength() : (currentPage * messagesPerPage)} of {getTotalLength()}</span>
              <span className='pagination-icon' onClick={() => {
                dispatch({ type: "PAGI_CLICK_LEFT", payload: !pagiClickLeft })
              }}>{logo.left_arrow_svg}</span>
              <span className='pagination-icon' onClick={() => {
                dispatch({ type: "PAGI_CLICK_RIGHT", payload: !pagiClickRight })
              }}>{logo.right_arrow_svg}</span>
            </div>
          </div>
        }
      </div>

    </div>
    <CompleteLoader isOpen={loader} />
  </>
  )
}

export default MessageBoxHeader;
